import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import { AppProvider } from "./context/AppProvider";

declare module "@mui/material/styles" {
  interface Palette {
    gray: Palette["primary"];
  }

  interface PaletteOptions {
    gray?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    gray: true;
  }
}

let theme = createTheme({
  palette: {
    primary: {
      main: "#ef7b22",
    },
    secondary: {
      main: "#adc6c9",
    },
  },
  typography: {
    fontFamily: `"DIN-Medium", Arial, sans-serif`,
  },
});

theme = createTheme(theme, {
  palette: {
    gray: theme.palette.augmentColor({
      color: {
        main: "rgba(0,0,0,0.6)",
      },
      name: "gray",
    }),
  },
});

function Entry() {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <AppProvider>
          <App />
        </AppProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
}

const root = createRoot(document.getElementById("content") as HTMLElement);

root.render(<Entry />);
