import { Box, Stack, Typography } from "@mui/material";
import { length, map } from "ramda";

import { L10n } from "@encoway/l10n";

import { ProgressTable } from "../../../components/ProgressTable";
import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../../components/modal/modal";
import { LvTable } from "../../../hooks/useLvTable";
import { t } from "../../shoppingCart/localizationUtils";
import { ReactComponent as CubeChecked } from "./cube_checked.svg";
import { useAllAnalyze } from "./useAllAnalyze";

type PositionsOverviewProps = Readonly<{
  lvTable: LvTable;
}>;

export function PositionsOverview({ lvTable }: PositionsOverviewProps) {
  const {
    selectedLvPositions,
    lvPositions,
    updateLvPosition,
    setSelectedPositionId,
  } = lvTable;
  const {
    analyzeAll,
    showLoadingModal,
    closeLoadingModal,
    allAnalyzeState,
    isAnyLoading,
    areAllPositionsAnalyzed,
    totalCount,
    successCount,
  } = useAllAnalyze(updateLvPosition, setSelectedPositionId);

  return (
    <>
      <Box
        sx={{
          padding: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Box>
          <Typography sx={{ marginTop: "-2px" }}>
            {t("lv_position_count_label")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              marginBottom: "-2px",
            }}
          >
            <CubeChecked width={16} height={16} />
            <Typography color="textSecondary" sx={{ fontSize: "small" }}>
              {length(selectedLvPositions)} / {length(lvPositions)}
            </Typography>
          </Box>
        </Box>
        <button
          className="button is-blue-dark"
          onClick={() => analyzeAll(lvPositions)}
          disabled={areAllPositionsAnalyzed(lvPositions)}
        >
          {t("lv_position_action_analyze_all")}
        </button>
      </Box>
      {showLoadingModal && (
        <Modal removeClipped={false}>
          <ModalHeader
            label={L10n.format("offer_management_lv_analyze_all_title")}
            onCancel={closeLoadingModal}
          />
          <ModalBodyWrapper
            label={L10n.format("offer_management_lv_analyze_all_body")}
            className="is-flex is-flex-direction-column gap-3"
          >
            <ProgressTable
              tableHeader={{
                lvPosition: L10n.format("lv_position_label"),
                recognizedProduct: L10n.format(
                  "offer_management_lv_recognized_product",
                ),
              }}
              tableCells={map(
                ({ isLoading, isSuccess, position, recognizedProduct }) => ({
                  isLoading,
                  isSuccess,
                  lvPosition: position.lvPosition,
                  recognizedProduct,
                }),
                allAnalyzeState,
              )}
              statusLabel={
                <Stack direction="row" spacing={0.5} sx={{ minWidth: "110px" }}>
                  <Typography variant="inherit">
                    {L10n.format("status")}:
                  </Typography>
                  <Typography variant="inherit" color="primary">
                    {successCount} / {totalCount}
                  </Typography>
                </Stack>
              }
            />
          </ModalBodyWrapper>
          <ModalFooter
            cancelLabel={L10n.format("cancel")}
            onCancel={closeLoadingModal}
            saveLabel={L10n.format("ok")}
            onSave={closeLoadingModal}
            disabled={isAnyLoading}
          />
        </Modal>
      )}
    </>
  );
}
