import Bacon from "baconjs";
import { defaultTo, isEmpty, isNil } from "ramda";
import {
  createContext,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";

import { ConfigurationService, isReady } from "@encoway/rest-api";

import { useApp } from "../hooks/useApp";
import { useCart } from "../hooks/useCart";
import { createHttp } from "../http/setupHttp";
import { getArticleName } from "../pages/configurator/configurationUtils";
import { getVariantConditionPrices } from "../service/configurationService";
import {
  ConfigurationStoreRestartParams,
  ConfigurationStoreStartParams,
  CreatedConfig,
  GuiTO,
  LoadedConfig,
  LocalStorageConfig,
  Reconfiguration,
  VariantConditionPrices,
} from "../types/configuration";
import { SERVICE_BASE_URL } from "./AppProvider";
import { expandConfigurationLoadTOIfNeeded } from "./configurationProviderUtils";

const LOCAL_STORAGE_CONFIGURATION = "configuration";

const initialLocalStorage: LocalStorageConfig = {
  configurationId: null,
  reconfiguration: null,
};

type ConfigurationContextType = {
  configurationId: string;
  articleName: string;
  guiTO: GuiTO;
  config: CreatedConfig | LoadedConfig;
  reconfiguration: Reconfiguration | null;
  variantConditionPrices: VariantConditionPrices;
  loading: boolean;
  bus: any;
  remove: () => Promise<void>;
  restart: (params: ConfigurationStoreRestartParams) => Promise<void>;
  save: (configurationId: string) => Promise<void>;
  start: (params: ConfigurationStoreStartParams) => Promise<void>;
};

export const ConfigurationContext =
  createContext<ConfigurationContextType | null>(null);

export function ConfigurationProvider({
  children,
}: Readonly<PropsWithChildren>) {
  const { language } = useApp();
  const { cartActions } = useCart();
  const [guiTO, setGuiTO] = useState<GuiTO | null>(null);
  const [config, setConfig] = useState<CreatedConfig | LoadedConfig | null>(
    null,
  );
  const [reconfiguration, setReconfiguration] =
    useState<Reconfiguration | null>(null);
  const [variantConditionPrices, setVariantConditionPrices] =
    useState<VariantConditionPrices | null>(null);
  const [loading, setLoading] = useState(false);

  const http = useMemo(() => createHttp(null), []);

  const bus = useMemo(() => new Bacon.Bus(), []);

  function resetStateWithLoading(isLoading: boolean) {
    setGuiTO(null);
    setConfig(null);
    setReconfiguration(null);
    setVariantConditionPrices(null);
    setLoading(isLoading);
  }

  const start = async ({
    articleName,
    configurationId,
  }: ConfigurationStoreStartParams) => {
    cartActions.resetProperties();
    if (articleName || configurationId) {
      resetStateWithLoading(true);
      const createdConfig = await ConfigurationService.create(
        http,
        SERVICE_BASE_URL,
        { articleName, configurationId },
        language,
      );
      createdConfig.settings({
        mappingOptions: { mappingProfile: "MAXIMUM_CONTENT_MAPPING" },
      });
      sessionStorage.setItem(
        LOCAL_STORAGE_CONFIGURATION,
        JSON.stringify({
          configurationId: createdConfig.id(),
          reconfiguration: null,
        }),
      );
      const { data } = await getVariantConditionPrices(
        createdConfig.id(),
        language,
      );
      setGuiTO(await createdConfig.ui());
      setConfig(createdConfig);
      setVariantConditionPrices(data);
      setLoading(false);
    }
  };

  const restart = async ({
    articleId,
    articleName,
    cartPosition,
    articleOrderStatus,
    configurationId = "",
    deliveryTimeWish = "",
  }: ConfigurationStoreRestartParams) => {
    resetStateWithLoading(true);
    try {
      const configurationLoadTO =
        await cartActions.getArticleConfiguration(articleId);
      const loadedConfig = await ConfigurationService.load(
        http,
        SERVICE_BASE_URL,
        configurationId
          ? { configurationId }
          : expandConfigurationLoadTOIfNeeded(
              configurationLoadTO,
              deliveryTimeWish,
            ),
        language,
      );
      loadedConfig.settings({
        mappingOptions: { mappingProfile: "MAXIMUM_CONTENT_MAPPING" },
      });
      sessionStorage.setItem(
        LOCAL_STORAGE_CONFIGURATION,
        JSON.stringify({
          configurationId: loadedConfig.id(),
          reconfiguration: {
            articleId,
            articleName,
            cartPosition,
            articleOrderStatus,
          },
        }),
      );
      const { data } = await getVariantConditionPrices(
        loadedConfig.id(),
        language,
      );
      setGuiTO(await loadedConfig.ui());
      setConfig(loadedConfig);
      setReconfiguration({
        configurationStatus: loadedConfig.cfg.configurationStatus,
        conflictedParameters: loadedConfig.cfg.conflictedParameters,
        failedParameters: loadedConfig.cfg.failedParameters,
        unsuccessfulParameters: loadedConfig.cfg.unsuccessfulParameters,
        articleId,
        cartPosition,
        articleOrderStatus,
      });
      setVariantConditionPrices(data);
      setLoading(false);
    } catch (e) {
      const createdConfig = await ConfigurationService.create(
        http,
        SERVICE_BASE_URL,
        { articleName },
        language,
      );
      createdConfig.settings({
        mappingOptions: { mappingProfile: "MAXIMUM_CONTENT_MAPPING" },
      });
      const { data } = await getVariantConditionPrices(
        createdConfig.id(),
        language,
      );
      setGuiTO(await createdConfig.ui());
      setConfig(createdConfig);
      setReconfiguration({
        configurationStatus: null,
        conflictedParameters: [],
        failedParameters: [],
        unsuccessfulParameters: [],
        articleId,
        cartPosition,
        articleOrderStatus,
      });
      setVariantConditionPrices(data);
      setLoading(false);
    }
  };

  const save = async (configurationId: string) => {
    if (isEmpty(configurationId)) {
      return;
    }
    setLoading(true);
    if (reconfiguration) {
      await cartActions
        .setPropertiesOnSave(reconfiguration.articleId)
        .then(() => {
          const readyState = isReady(guiTO?.rootContainer);
          cartActions.update(
            reconfiguration.articleId,
            configurationId,
            reconfiguration.cartPosition,
            reconfiguration.articleOrderStatus,
            readyState,
          );
        });
    } else {
      await cartActions
        .saveConfiguration(configurationId)
        .then((cartResult: any) => {
          const articles = cartResult.articles.subArticles;
          cartActions.setPropertiesOnSave(
            articles[articles.length - 1].articleId,
          );
        });
    }
    sessionStorage.setItem(
      LOCAL_STORAGE_CONFIGURATION,
      JSON.stringify(initialLocalStorage),
    );
    resetStateWithLoading(false);
  };

  const remove = async () => {
    if (config) {
      await config.stop();
      resetStateWithLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      const lsConfig: LocalStorageConfig = JSON.parse(
        sessionStorage.getItem(LOCAL_STORAGE_CONFIGURATION) ?? "{}",
      );
      if (isNil(lsConfig.configurationId)) {
        return;
      }
      if (isNil(lsConfig.reconfiguration)) {
        await start({ configurationId: lsConfig.configurationId });
        return;
      }
      const { articleId, articleName, cartPosition, articleOrderStatus } =
        lsConfig.reconfiguration;
      await restart({
        articleId,
        articleName,
        cartPosition,
        articleOrderStatus,
      });
    })();
    return bus
      .filter(({ event }: { event: string }) => event === "UpdateState")
      .onValue(
        (e: { rawState: SetStateAction<GuiTO | null> }) =>
          e.rawState && setGuiTO(e.rawState),
      );
  }, []);

  useEffect(() => {
    (async () => {
      if (config?.id()) {
        const { data } = await getVariantConditionPrices(config.id(), language);
        setVariantConditionPrices(data);
      }
    })();
  }, [language, guiTO]);

  // The as syntax is not optimal, but it is the best we can do here. The
  // objects are initialized with null, but are checked immediately before the
  // rest of the app is rendered. This means that we can assume that these
  // objects will be defined wherever we want to use them later in the app.
  const value = {
    configurationId: defaultTo("", config?.id()),
    articleName: getArticleName(config?.cfg),
    guiTO: guiTO as GuiTO,
    config: config as CreatedConfig | LoadedConfig,
    reconfiguration,
    variantConditionPrices: variantConditionPrices as VariantConditionPrices,
    loading,
    bus,
    start,
    restart,
    save,
    remove,
  };

  return (
    <ConfigurationContext.Provider value={value}>
      {children}
    </ConfigurationContext.Provider>
  );
}
