import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  curry,
  gte,
  isEmpty,
  isNil,
  isNotEmpty,
  isNotNil,
  lte,
  map,
  prop,
  tail,
  test,
  toUpper,
} from "ramda";
import { ChangeEvent, FocusEvent, useEffect, useRef, useState } from "react";

import { L10n } from "@encoway/l10n";

import { AlertDialog } from "../../../components/AlertDialog";
import { Dropzone } from "../../../components/Dropzone";
import { LvImportOptions, VALID_LV_FILE_TYPES } from "./LvImportTab";
import { ChooseFileInput, NumberInputField } from "./LvImportTabComponents";
import { SmallBlackLabel } from "./LvPositionView";

type PdfSelectionDialogProps = Readonly<{
  handleInputClick: () => void;
  handleFileChange: (file: File) => void;
  selectedFile: File | null;
}>;

function PdfSelectionDialog(props: PdfSelectionDialogProps) {
  const { handleInputClick, handleFileChange, selectedFile } = props;
  const previewSrcRef = useRef<string | null>(null);

  useEffect(() => {
    if (selectedFile) {
      const blobUrl = URL.createObjectURL(selectedFile);
      previewSrcRef.current = blobUrl;
    }
    return () => {
      if (previewSrcRef.current) {
        URL.revokeObjectURL(previewSrcRef.current);
      }
    };
  }, [selectedFile]);

  if (isNil(selectedFile) || isNil(previewSrcRef.current)) {
    return (
      <Dropzone handleDrop={handleFileChange}>
        <Stack alignItems="center" justifyContent="center" gap={4.8}>
          <Typography sx={{ fontSize: "1.4rem", fontWeight: "bold" }}>
            {L10n.format("offer_management_create_lv_upload_drag_information")}
          </Typography>
          <Typography>
            {L10n.format("offer_management_create_lv_upload_or")}
          </Typography>
          <ChooseFileInput onClick={handleInputClick} />
          <Stack alignItems="center" position="absolute" bottom="4rem">
            <Typography fontWeight="bold">
              {L10n.format(
                "offer_management_create_lv_upload_supported_file_types_label",
              )}
            </Typography>
            <Typography>
              {L10n.format(
                "offer_management_create_lv_supported_file_size_label",
              )}
            </Typography>
            <Typography>
              {map(
                (filetype) => toUpper(tail(prop("key", filetype))),
                VALID_LV_FILE_TYPES,
              )}
            </Typography>
          </Stack>
        </Stack>
      </Dropzone>
    );
  }

  return (
    <iframe
      src={previewSrcRef.current}
      style={{ flex: 1 }}
      title={L10n.format("offer_management_lv_pdf_preview")}
    />
  );
}

const StyledAccordion = styled(Accordion)({
  boxShadow: "none",
  "::before": {
    content: "none",
  },
  ".MuiAccordionSummary-root": {
    padding: 0,
  },
  ".MuiAccordionSummary-expandIconWrapper": {
    paddingX: "4px",
  },
  ".MuiAccordionDetails-root": {
    padding: 0,
  },
});

type PdfOverviewProps = Readonly<{
  lvImportOptions: LvImportOptions;
  selectedFile: File | null;
  totalPages: number;
  onRemoveFile: () => void;
  setLvImportOptionsValue: (
    name: keyof LvImportOptions,
    value: string | number,
  ) => void;
  unsetAlreadyImported: () => void;
  errorMessage: string;
  hasWarning: boolean;
}>;

function PdfOverview(props: PdfOverviewProps) {
  const {
    lvImportOptions,
    selectedFile,
    totalPages,
    onRemoveFile,
    setLvImportOptionsValue,
    unsetAlreadyImported,
    errorMessage,
    hasWarning,
  } = props;
  const [alertDialogSubmit, setAlertDialogSubmit] = useState<{
    open: boolean;
    onSubmit: () => void;
  }>({ open: false, onSubmit: () => {} });

  const handleOnChange = curry(
    (name: keyof LvImportOptions, event: ChangeEvent<HTMLInputElement>) => {
      if (hasWarning) {
        setAlertDialogSubmit({ open: true, onSubmit: unsetAlreadyImported });
        return;
      }
      setLvImportOptionsValue(name, event.target.value);
      unsetAlreadyImported();
    },
  );

  const handleOnBlur = curry(
    (
      name: keyof LvImportOptions,
      emptyValue: number,
      event: FocusEvent<HTMLInputElement>,
    ) => {
      const value = lvImportOptions[name];
      const { min, max } = event.target;
      if (isEmpty(value)) {
        setLvImportOptionsValue(name, emptyValue);
        return;
      }
      if (gte(parseInt(value), parseInt(max))) {
        setLvImportOptionsValue(name, parseInt(max));
        return;
      }
      if (lte(parseInt(value), parseInt(min))) {
        setLvImportOptionsValue(name, parseInt(min));
        return;
      }
      if (test(/[.,]/, value)) {
        setLvImportOptionsValue(name, parseInt(value));
      }
    },
  );

  if (isNotNil(selectedFile)) {
    return (
      <>
        <Stack
          sx={{ backgroundColor: "#fff", flex: 1, padding: "3rem" }}
          spacing={6}
        >
          <Stack spacing={2} sx={{ flex: 1 }}>
            <SmallBlackLabel>
              {L10n.format("offer_management_create_lv")}
            </SmallBlackLabel>
            <SmallBlackLabel>
              {L10n.format("offer_management_create_lv_upload_filename_label")}
            </SmallBlackLabel>
            <Stack
              sx={{
                borderTop: "1px solid #adc3c7",
                borderBottom: "1px solid #adc3c7",
                justifyContent: "space-between",
                alignItems: "center",
                paddingY: "0.4rem",
                paddingLeft: "0.6rem",
              }}
              direction="row"
            >
              <Typography variant="body2">{selectedFile.name}</Typography>
              <IconButton
                size="small"
                onClick={() => {
                  if (hasWarning) {
                    setAlertDialogSubmit({
                      open: true,
                      onSubmit: onRemoveFile,
                    });
                    return;
                  }
                  onRemoveFile();
                }}
                sx={{ color: "#000" }}
              >
                <DeleteIcon sx={{ fontSize: "1.25rem" }} />
              </IconButton>
            </Stack>
          </Stack>
          <StyledAccordion disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#000" }} />}
            >
              <Typography component="span" sx={{ fontWeight: "bold" }}>
                {L10n.format(
                  "offer_management_create_lv_import_expert_settings",
                )}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <NumberInputField
                label="offer_management_create_lv_import_first_page"
                min={1}
                max={lvImportOptions.lastPage}
                value={lvImportOptions.firstPage}
                onChange={handleOnChange("firstPage")}
                onBlur={handleOnBlur("firstPage", 1)}
              />
              <NumberInputField
                label="offer_management_create_lv_import_last_page"
                min={lvImportOptions.firstPage}
                max={totalPages}
                value={lvImportOptions.lastPage}
                onChange={handleOnChange("lastPage")}
                onBlur={handleOnBlur("lastPage", totalPages)}
              />
            </AccordionDetails>
          </StyledAccordion>
          {isNotEmpty(errorMessage) && (
            <p className="has-text-danger">{L10n.format(errorMessage)}</p>
          )}
        </Stack>
        <AlertDialog
          open={alertDialogSubmit.open}
          message={L10n.format("lv_warning_reset_positions")}
          onClose={() =>
            setAlertDialogSubmit({ open: false, onSubmit: () => {} })
          }
          onSubmit={alertDialogSubmit.onSubmit}
        />
      </>
    );
  }

  return null;
}

type DocumentContainerProps = Readonly<{
  lvImportOptions: LvImportOptions;
  selectedFile: File | null;
  totalPages: number;
  handleInputClick: () => void;
  handleFileChange: (file: File) => void;
  onRemoveFile: () => void;
  setLvImportOptionsValue: (
    name: keyof LvImportOptions,
    value: string | number,
  ) => void;
  unsetAlreadyImported: () => void;
  errorMessage: string;
  hasWarning: boolean;
}>;

export const DocumentContainer = ({
  selectedFile,
  handleInputClick,
  handleFileChange,
  ...props
}: DocumentContainerProps) => (
  <Grid container flex={1}>
    <Grid
      size={4}
      justifyContent="center"
      sx={{
        borderRight: "1px solid #adc3c7",
        display: "flex",
        flexFlow: "column",
      }}
      position="relative"
    >
      <PdfSelectionDialog
        selectedFile={selectedFile}
        handleInputClick={handleInputClick}
        handleFileChange={handleFileChange}
      />
    </Grid>
    <Grid size={8} sx={{ backgroundColor: "#eef4f4", display: "flex" }}>
      <PdfOverview selectedFile={selectedFile} {...props} />
    </Grid>
  </Grid>
);
