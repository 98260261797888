import { faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AutoAwesome } from "@mui/icons-material";
import { styled, TableCell, TableRow, Typography } from "@mui/material";
import { find, pathOr, propEq } from "ramda";
import { ReactNode } from "react";

import { L10n } from "@encoway/l10n";
import { Constants } from "@encoway/react-components";
import { specState } from "@encoway/rest-api";

import { getConflictFreeParameterTO } from "../../../../service/configurationService";
import {
  LvBaseExtended,
  LvConfiguratorComponentProps,
} from "../../../../types/lvTable";
import { isOriginalValue } from "../../../../utilities/lvUtils";
import { t } from "../../../shoppingCart/localizationUtils";
import { LvDropdown } from "./LvDropdown";
import { ConfiguratorTableCell } from "./LvProductField";

export const ConfiguratorTableIconCell = styled(TableCell)`
  padding: 0 6px;

  svg {
    transform: translateY(2px);
  }

  .zauberstab {
    display: block !important;
    height: 16px !important;
    width: 17px !important;
  }
  .monitor {
    display: block !important;
    height: 16px !important;
    width: 16px !important;
  }
`;

export const ICON_MAP: Record<string, ReactNode> = {
  SET_BY_USER: (
    <FontAwesomeIcon
      icon={faUser}
      title={t("SET_BY_USER")}
      style={{ color: "#ef7b22", height: "1rem", paddingLeft: "3px" }}
    />
  ),
  SET_BY_DEFAULT: <span className="zauberstab" title={t("SET_BY_DEFAULT")} />,
  SET_BY_SYSTEM: <span className="monitor" title={t("SET_BY_SYSTEM")} />,
  UNDO: (
    <FontAwesomeIcon
      icon={faTimes}
      title={t("undo")}
      style={{ color: "#ef7b22", height: "1rem" }}
    />
  ),
};

export function LvConfiguratorDropdownField(
  props: LvConfiguratorComponentProps,
) {
  const { data, onValueChanged, options, config } = props;

  const lvParameter = find<LvBaseExtended>(
    propEq(data.name, "name"),
    pathOr([], ["selectedPosition", "lvParameters"], options),
  );

  const selectedValue = {
    value: pathOr("", ["selectedValues", 0, "value"], data),
    translatedValue: pathOr("", ["selectedValues", 0, "translatedValue"], data),
  };

  const isUndoable =
    data.undoable && data.editable && specState(data) === "SET_BY_USER";

  const isOriginal = isOriginalValue(lvParameter?.value, selectedValue.value);

  return (
    <TableRow>
      <ConfiguratorTableIconCell align="center">
        {isOriginal ? (
          <AutoAwesome style={{ color: "#ef7b22" }} />
        ) : (
          ICON_MAP[specState(data)]
        )}
      </ConfiguratorTableIconCell>
      <ConfiguratorTableCell>{data.translatedName}</ConfiguratorTableCell>
      <ConfiguratorTableCell>
        <div className="control is-expanded">
          <LvDropdown
            values={data.values!}
            selectedValue={selectedValue}
            originalValue={lvParameter?.originalValue}
            onSelectValue={(value) => onValueChanged(data, value)}
            getParameterTO={() =>
              getConflictFreeParameterTO(config.id(), config.lang, data.id)
            }
            {...(isUndoable && { resetValue: Constants.Undo })}
          />
        </div>
      </ConfiguratorTableCell>
      <ConfiguratorTableCell>{lvParameter?.recognized}</ConfiguratorTableCell>
      <ConfiguratorTableCell>
        {options && lvParameter?.isFromReference && (
          <Typography color="textSecondary" sx={{ fontSize: "small" }}>
            {L10n.format("offer_management_lv_taken_from_position", {
              lvResolvedReference: options.selectedPosition.lvResolvedReference,
            })}
          </Typography>
        )}
      </ConfiguratorTableCell>
    </TableRow>
  );
}
