import { isNil, pathEq } from "ramda";
import { useState } from "react";

import { ConfigurationService } from "@encoway/rest-api";

import { Config } from "../config/config";
import { SERVICE_BASE_URL } from "../context/AppProvider";
import { createHttp } from "../http/setupHttp";
import { CreatedConfig } from "../types/configuration";
import { LvBaseExtended, MinimalParameter } from "../types/lvTable";
import {
  getGuiParameters,
  getParametersSetByUser,
  setConfigValues,
} from "../utilities/lvUtils";
import { useApp } from "./useApp";

export const useLvConfiguration = () => {
  const { language, identityStore } = useApp();
  const [config, setConfig] = useState<CreatedConfig | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  async function startConfAndSetValues(
    parameters: LvBaseExtended[] | MinimalParameter[],
    product?: MinimalParameter,
  ) {
    const cs = await ConfigurationService.create(
      createHttp(null),
      SERVICE_BASE_URL,
      { articleName: Config.LvImport.studioModel },
      language,
    );
    cs.settings({
      mappingOptions: { mappingProfile: "MAXIMUM_CONTENT_MAPPING" },
    });
    await setConfigValues(
      cs,
      getParametersSetByUser(parameters),
      identityStore,
      pathEq("SET_BY_USER", ["selectionSource"], product) ? product : undefined,
    );
    setConfig(cs);
  }

  async function stopConfAndGetValues() {
    if (isNil(config)) return;
    const guiParameters = await getGuiParameters(config);
    await config.stop();
    setConfig(null);
    return guiParameters;
  }

  return {
    config,
    isLoading,
    setIsLoading,
    startConfAndSetValues,
    stopConfAndGetValues,
  };
};

export type LvConfiguration = ReturnType<typeof useLvConfiguration>;
