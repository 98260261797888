export const CONSTANTS = {
  Tabs: {
    productSelection: "#/catalog",
    configuration: "#/configuration",
    shoppingCart: "#/cart",
  },
  Pages: {
    offerManagement: "#/offerManagement",
    customerManagement: "#/customerManagement",
    save: "#/save",
    devLogin: "#/devLogin",
  },
  Images: {
    Catalog: "api/catalog/media?id=",
  },
} as const;

export const ROUTES = {
  HOME: "/",
  ERROR: "*",
  CART: "/cart",
  OPEN_SHARED_CART: "/share/:shareId",
  PRODUCTS: "/catalog",
  OFFER_MANAGEMENT: "/offerManagement",
  OFFER_MANAGEMENT_OPEN_OFFER: "/offerManagement/:offerId",
  OFFER_MANAGEMENT_OPEN_DIALOG: "/offerManagement/new",
  CUSTOMER_MANAGEMENT: "/customerManagement",
  CONFIGURE_FROM_PRODUCT: "/configuration/product/:articleName",
  CONFIGURE_FROM_ID: "/configuration/id/:configurationId",
  RECONFIGURE_FROM_ID:
    "reconfiguration/id/:configurationId/:articleName/:articleId/:cartPosition/:articleOrderStatus",
  RECONFIGURE_FROM_PRODUCT:
    "/reconfiguration/product/:articleName/:articleId/:cartPosition/:articleOrderStatus",
  DEV_LOGIN: "/devLogin",
} as const;
