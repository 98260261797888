import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, styled } from "@mui/material";
import classNames from "classnames";
import { propEq } from "ramda";
import { Dispatch, SetStateAction, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

import { AlertDialog } from "../../../components/AlertDialog";
import { ModalBodyWrapper } from "../../../components/modal/modal";
import { useLvConfiguration } from "../../../hooks/useLvConfiguration";
import { LvTable } from "../../../hooks/useLvTable";
import { ResolvedPosition } from "../../../types/lvTable";
import {
  getPositionIndex,
  LV_TAB_ID,
  LvTabId,
  startNextPositionConfiguration,
  stopCurrentPositionConfiguration,
} from "../../../utilities/lvUtils";
import { t } from "../../shoppingCart/localizationUtils";
import { LvModalFooter } from "./LvModalFooter";
import { LvPositionView } from "./LvPositionView";
import { MoreIconMenu } from "./MoreIconMenu";
import { PositionsOverview } from "./PositionsOverview";
import { PositionsTreeView } from "./PositionsTreeView";

import "./lvTableTab.scss";

const ButtonsRight = styled("div")({
  display: "flex",
  flex: "1",
  justifyContent: "flex-end",
});

type PositionMenuProps = Readonly<{
  selectedPosition: ResolvedPosition;
  lvTable: LvTable;
  isDisabled: boolean;
}>;

function PositionMenu({
  selectedPosition,
  lvTable,
  isDisabled,
}: PositionMenuProps) {
  const [showPositionDeleteWarning, setShowPositionDeleteWarning] =
    useState(false);

  const menuItems = [
    {
      key: "change-position",
      children: propEq("folder", "lvType", selectedPosition)
        ? t("lv_position_action_change_to_position")
        : t("lv_position_action_change_to_folder"),
      onClick: () => lvTable.togglePositionType(selectedPosition.id),
    },
    {
      key: "merge-positions",
      children: t("lv_position_action_merge_position"),
      disabled: true,
    },
    {
      key: "delete-position",
      children: t("lv_position_action_delete_position"),
      onClick: () => setShowPositionDeleteWarning(true),
      disabled: lvTable.isOnlyOneLvPositionLeft,
    },
  ];

  return (
    <Box
      sx={{
        border: "1px solid rgb(219, 219, 219)",
        alignContent: "center",
        height: "100%",
      }}
    >
      <MoreIconMenu
        sx={{ pointerEvents: isDisabled ? "none" : "auto" }}
        menuItems={menuItems}
      />
      <AlertDialog
        open={showPositionDeleteWarning}
        message={t("lv_warning_position_delete_configuration")}
        onClose={() => setShowPositionDeleteWarning(false)}
        onSubmit={() => lvTable.deleteLvPosition(selectedPosition.id)}
      />
    </Box>
  );
}

type LvTableTabProps = {
  isHidden: boolean;
  lvTable: LvTable;
  onCancel: () => void;
  setSelectedTab: Dispatch<SetStateAction<LvTabId>>;
};

export const LvTableTab = ({
  isHidden,
  lvTable,
  onCancel,
  setSelectedTab,
}: LvTableTabProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [panelSize, setPanelSize] = useState(30);
  const lvConfiguration = useLvConfiguration();

  const { selectedPositionIndex, hasNoNextPosition, hasNoPreviousPosition } =
    getPositionIndex(lvTable.selectedPositionId, lvTable.lvPositions);

  async function goToPosition(previousOrNext: -1 | 1, isDisabled: boolean) {
    if (isDisabled || lvConfiguration.isLoading) {
      return;
    }
    lvConfiguration.setIsLoading(true);
    await stopCurrentPositionConfiguration(
      lvTable.selectedPositionId,
      lvTable.updateLvPosition,
      lvConfiguration.stopConfAndGetValues,
    );
    const nextPositionId =
      lvTable.lvPositions[selectedPositionIndex + previousOrNext].id;
    await startNextPositionConfiguration(
      nextPositionId,
      lvTable.lvPositions,
      lvConfiguration.startConfAndSetValues,
    );
    lvTable.setSelectedPositionId(nextPositionId);
    lvConfiguration.setIsLoading(false);
  }

  async function fetchAddressAndConstruction() {
    setIsLoading(true);
    try {
      await lvTable.getAddressAndConstruction();
      setSelectedTab(LV_TAB_ID.LV_HEADER_DATA);
    } catch (error) {
      setSelectedTab(LV_TAB_ID.LV_HEADER_DATA);
      lvTable.setFileToOldFile();
    } finally {
      setIsLoading(false);
    }
  }

  const selectedPosition: ResolvedPosition | undefined =
    lvTable.lvPositions[selectedPositionIndex];

  return (
    <>
      <ModalBodyWrapper
        className={classNames("select-positions-tab", {
          "is-hidden": isHidden,
        })}
      >
        <div className="lv-table slim-scrollbar">
          <PanelGroup direction="horizontal">
            <Panel
              defaultSize={30}
              style={{
                display: "flex",
                flexDirection: "column",
                overflowX: "hidden",
              }}
            >
              <PositionsOverview lvTable={lvTable} />
              <div className="horizontal-divider" />
              <PositionsTreeView
                lvTable={lvTable}
                lvConfiguration={lvConfiguration}
              />
            </Panel>
            <PanelResizeHandle className="vertical-divider" />
            <Panel
              defaultSize={70}
              style={{
                overflowX: "hidden",
                overflowY: "auto",
              }}
              onResize={setPanelSize}
            >
              {selectedPosition && (
                <LvPositionView
                  updatePosition={lvTable.updateLvPosition}
                  updatePositionParameter={
                    lvTable.updateLvConfigurationParameter
                  }
                  selectedPosition={selectedPosition}
                  lvConfiguration={lvConfiguration}
                  panelSize={panelSize}
                  positionMenu={
                    <PositionMenu
                      selectedPosition={selectedPosition}
                      lvTable={lvTable}
                      isDisabled={lvConfiguration.isLoading}
                    />
                  }
                />
              )}
            </Panel>
          </PanelGroup>
        </div>
      </ModalBodyWrapper>
      <LvModalFooter
        className={classNames({ "is-hidden": isHidden })}
        saveLabel={t("lv_position_continue")}
        saveButtonId="lv_position_continue_header_data"
        onSave={() =>
          lvTable.areLvFilesEqual
            ? setSelectedTab(LV_TAB_ID.LV_HEADER_DATA)
            : fetchAddressAndConstruction()
        }
        cancelLabel={t("cancel")}
        onCancel={onCancel}
        disabled={!lvTable.isAnyProductSelected}
        isLoading={isLoading}
      >
        <ButtonsRight>
          <div>
            <button
              data-button-id="button-previous-lv-position"
              className="button is-blue-dark"
              onClick={() => goToPosition(-1, hasNoPreviousPosition)}
              disabled={hasNoPreviousPosition || lvConfiguration.isLoading}
            >
              <span className="icon">
                <FontAwesomeIcon icon={faAngleLeft} />
              </span>
              <span>{t("lv_position_previous_position")}</span>
            </button>
            <button
              data-button-id="button-next-lv-position"
              className="button is-blue-dark"
              onClick={() => goToPosition(1, hasNoNextPosition)}
              disabled={hasNoNextPosition || lvConfiguration.isLoading}
            >
              <span>{t("lv_position_next_position")}</span>
              <span className="icon">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </button>
          </div>
        </ButtonsRight>
      </LvModalFooter>
    </>
  );
};
