import {
  any,
  count,
  curry,
  equals,
  gt,
  isEmpty,
  isNotNil,
  length,
  path,
  prop,
  propEq,
  reduce,
} from "ramda";
import { useRef, useState } from "react";
import { useImmer } from "use-immer";

import { L10n } from "@encoway/l10n";
import { ConfigurationService } from "@encoway/rest-api";

import { Config } from "../../../config/config";
import { SERVICE_BASE_URL } from "../../../context/AppProvider";
import { useApp } from "../../../hooks/useApp";
import { LvTable } from "../../../hooks/useLvTable";
import { createHttp } from "../../../http/setupHttp";
import { ResolvedPosition } from "../../../types/lvTable";
import {
  getGuiParameters,
  initializeLvPosition,
  setConfigValues,
} from "../../../utilities/lvUtils";

type AllAnalyzeStateEntry = {
  position: ResolvedPosition;
  isLoading: boolean;
  isSuccess: boolean;
  recognizedProduct: string;
};

function createAllAnalyzeState(positions: ResolvedPosition[]) {
  return reduce<ResolvedPosition, AllAnalyzeStateEntry[]>(
    (acc, position) => {
      const { lvText, lvType, lvQuantity } = position;
      if (isEmpty(lvText) || equals(lvType, "folder") || isNotNil(lvQuantity)) {
        return acc;
      }
      return [
        ...acc,
        { position, isLoading: true, isSuccess: false, recognizedProduct: "" },
      ];
    },
    [],
    positions,
  );
}

export function useAllAnalyze(
  updateLvPosition: LvTable["updateLvPosition"],
  setSelectedPositionId: LvTable["setSelectedPositionId"],
) {
  const { language, identityStore } = useApp();
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [allAnalyzeState, setAllAnalyzeState] = useImmer<
    AllAnalyzeStateEntry[]
  >([]);
  const abortControllerRef = useRef<AbortController | null>(null);

  const isAnyLoading = any(prop("isLoading"), allAnalyzeState);

  const totalCount = length(allAnalyzeState);

  const successCount = count(propEq(true, "isSuccess"), allAnalyzeState);

  function areAllPositionsAnalyzed(positions: ResolvedPosition[]) {
    return isEmpty(createAllAnalyzeState(positions));
  }

  function openLoadingModal() {
    setShowLoadingModal(true);
  }

  function closeLoadingModal() {
    abortControllerRef.current?.abort();
    setShowLoadingModal(false);
  }

  const analyzePosition = curry(
    async (
      abortController: AbortController,
      stateEntry: AllAnalyzeStateEntry,
      entryIndex: number,
    ) => {
      try {
        const initializedPosition = await initializeLvPosition(
          stateEntry.position,
          abortController.signal,
        );
        const cs = await ConfigurationService.create(
          createHttp(null),
          SERVICE_BASE_URL,
          { articleName: Config.LvImport.studioModel },
          language,
        );
        cs.settings({
          mappingOptions: { mappingProfile: "MAXIMUM_CONTENT_MAPPING" },
        });
        await setConfigValues(
          cs,
          initializedPosition.lvParameters,
          identityStore,
        );
        const { parameters, product } = await getGuiParameters(cs);
        await cs.stop();
        updateLvPosition(stateEntry.position.id, {
          ...initializedPosition,
          parameters: parameters,
          product: isEmpty(product.value) ? undefined : product,
        });
        setAllAnalyzeState((draft) => {
          draft[entryIndex].isSuccess = true;
          draft[entryIndex].recognizedProduct = gt(product.selectableCount, 1)
            ? L10n.format("offer_management_lv_number_of_possible_products", {
                count: product.selectableCount,
              })
            : product.translatedValue;
        });
      } catch (error) {
        console.error(path(["message"], error));
      } finally {
        setAllAnalyzeState((draft) => {
          draft[entryIndex].isLoading = false;
        });
      }
    },
  );

  async function analyzeAll(positions: ResolvedPosition[]) {
    const preparedPositions = createAllAnalyzeState(positions);
    setSelectedPositionId(null);
    setAllAnalyzeState(preparedPositions);
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    openLoadingModal();
    const analyzePositionWithAbortController = analyzePosition(abortController);
    for (const [index, value] of preparedPositions.entries()) {
      await analyzePositionWithAbortController(value, index);
    }
  }

  return {
    analyzeAll,
    showLoadingModal,
    closeLoadingModal,
    allAnalyzeState,
    isAnyLoading,
    areAllPositionsAnalyzed,
    totalCount,
    successCount,
  };
}
