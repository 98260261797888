import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, styled } from "@mui/material";
import { useState } from "react";

import { L10n } from "@encoway/l10n";

import { AlertDialog } from "../../../components/AlertDialog";
import { ModalFooter, ModalFooterProps } from "../../../components/modal/modal";

type LvModalFooterProps = Readonly<
  ModalFooterProps & {
    cancelLabel: string;
    onCancel: () => void;
    hasWarning?: boolean;
  }
>;

const ModalFooterWrapper = styled(ModalFooter)({
  flexDirection: "row-reverse",
  gap: "0.5rem",
});

const LvButtons = styled(Box)({
  justifyContent: "space-between",
  display: "flex",
  flex: "1",
});

const MiddleButtons = styled(Box)({
  display: "flex",
  flex: "1",
  flexDirection: "row",
});

export function LvModalFooter({
  cancelLabel,
  onCancel,
  children,
  hasWarning = true,
  ...props
}: LvModalFooterProps) {
  const [showCancelWarning, setShowCancelWarning] = useState(false);

  function onClickCancel() {
    if (hasWarning) {
      setShowCancelWarning(true);
      return;
    }
    onCancel();
  }

  return (
    <ModalFooterWrapper {...props} onCancel={onCancel}>
      <LvButtons>
        <button
          data-button-id="button-cancel-lv-position"
          className="button cancel-button is-info is-blue-dark"
          onClick={onClickCancel}
        >
          <span>{cancelLabel}</span>
          <span className="icon">
            <FontAwesomeIcon icon={faBan} />
          </span>
        </button>
        <AlertDialog
          open={showCancelWarning}
          message={L10n.format("offer_management_lv_cancel_warning")}
          onClose={() => setShowCancelWarning(false)}
          onSubmit={onCancel}
        />
        <MiddleButtons>{children}</MiddleButtons>
      </LvButtons>
    </ModalFooterWrapper>
  );
}
