import {
  styled,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { is, path, toPairs, values } from "ramda";
import { ReactNode } from "react";

import { L10n } from "@encoway/l10n";

import { important, mapIndexed } from "../utilities/utilities";
import { ProgressButton } from "./progressButton";

const StyledTableCell = styled(TableCell)({
  fontWeight: "bold",
  textWrap: "nowrap",
});

type HeaderValue =
  | string
  | number
  | { label: string; align: TableCellProps["align"] };

function isHeaderObject(
  value: HeaderValue,
): value is { label: string; align: TableCellProps["align"] } {
  return is(Object, value);
}

type TableHeadCellProps = Readonly<{
  headerValue: HeaderValue;
}>;

function TableHeadCell({ headerValue }: TableHeadCellProps) {
  if (isHeaderObject(headerValue)) {
    const { label, align } = headerValue;
    return (
      <StyledTableCell sx={{ textAlign: important(align) }}>
        {label}
      </StyledTableCell>
    );
  }
  return <StyledTableCell>{headerValue}</StyledTableCell>;
}

type CellValue = string | number | { sx: SxProps; value: string | number };

function isCellObject(
  value: CellValue,
): value is { sx: SxProps; value: string | number } {
  return is(Object, value);
}

type TableBodyCellProps = Readonly<{
  cellValue: CellValue;
  align: TableCellProps["align"];
}>;

function TableBodyCell({ cellValue, align }: TableBodyCellProps) {
  if (isCellObject(cellValue)) {
    const { sx, value } = cellValue;
    return (
      <TableCell sx={{ textAlign: important(align), ...sx }}>{value}</TableCell>
    );
  }
  return (
    <TableCell sx={{ textAlign: important(align) }}>{cellValue}</TableCell>
  );
}

type ProgressTableProps<T> = Readonly<{
  tableHeader: T;
  tableCells: Array<
    {
      isLoading: boolean | undefined;
      isSuccess: boolean | undefined;
    } & {
      [K in keyof T]: CellValue;
    }
  >;
  statusLabel?: ReactNode;
}>;

export function ProgressTable<T extends Record<string, HeaderValue>>({
  tableHeader,
  tableCells,
  statusLabel = L10n.format("status"),
}: ProgressTableProps<T>) {
  return (
    <TableContainer>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell>{statusLabel}</StyledTableCell>
            {mapIndexed(
              (headerValue, headerIndex) => (
                <TableHeadCell key={headerIndex} headerValue={headerValue} />
              ),
              values(tableHeader),
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {mapIndexed(
            ({ isLoading, isSuccess, ...rest }, rowIndex) => (
              <TableRow key={rowIndex}>
                <TableCell>
                  <ProgressButton isLoading={isLoading} isSuccess={isSuccess} />
                </TableCell>
                {mapIndexed(([cellKey, cellValue], cellIndex) => {
                  const align = path<TableCellProps["align"]>(
                    ["align"],
                    tableHeader[cellKey],
                  );
                  return (
                    <TableBodyCell
                      key={cellIndex}
                      cellValue={cellValue}
                      align={align}
                    />
                  );
                }, toPairs(rest))}
              </TableRow>
            ),
            tableCells,
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
