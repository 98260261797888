import { Box, Stack, Table, TableBody, TableContainer } from "@mui/material";
import { find, map, propEq, reject } from "ramda";

import { L10n } from "@encoway/l10n";
import { Viewport } from "@encoway/react-components";

import { CONFIGURATOR_COMPONENTS } from "../../../constants";
import {
  ConfiguratorComponentProps,
  ContainerTO,
} from "../../../types/configuration";
import { LvTableHead } from "../lvTable/components/LvTableHead";
import { SmallBlackLabel } from "./LvPositionView";

export function LvConfiguratorTable(
  props: Readonly<ConfiguratorComponentProps<ContainerTO>>,
) {
  const doorsParameter = find(propEq("doors", "name"), props.data.parameters);
  const parameters = reject(propEq("doors", "name"), props.data.parameters);

  if (doorsParameter) {
    return (
      <Stack spacing={3}>
        <Box>
          <SmallBlackLabel>
            {L10n.format("offer_management_lv_chosen_product")}
          </SmallBlackLabel>
          {Viewport.instance(CONFIGURATOR_COMPONENTS.LV_PRODUCT_DROPDOWN, {
            ...props,
            data: doorsParameter,
            key: doorsParameter.id,
          })}
        </Box>
        <Box>
          <SmallBlackLabel>
            {L10n.format("offer_management_lv_product_filter")}
          </SmallBlackLabel>
          <TableContainer>
            <Table>
              <LvTableHead />
              <TableBody>
                {map(
                  (parameter) =>
                    Viewport.instance(parameter.viewPort!, {
                      ...props,
                      data: parameter,
                      key: parameter.id,
                    }),
                  parameters,
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Stack>
    );
  }

  return null;
}
