import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  MenuItemProps,
  SxProps,
} from "@mui/material";
import { curry, map } from "ramda";
import { MouseEvent, useState } from "react";

type CustomMenuItemProps = MenuItemProps & {
  onClick?: () => void;
};

type MoreIconMenuProps = Readonly<{
  menuItems: CustomMenuItemProps[];
  sx?: SxProps;
}>;

export function MoreIconMenu({ menuItems, sx }: MoreIconMenuProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function onOpen(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  function onClose(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setAnchorEl(null);
  }

  const handleMenuClick = curry(
    (onClick: () => void | undefined, event: MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();
      onClick?.();
      setAnchorEl(null);
    },
  );

  return (
    <Box sx={sx}>
      <IconButton size="small" onClick={onOpen}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        MenuListProps={{
          dense: true,
        }}
      >
        {map(
          ({ key, children, onClick, ...restProps }) => (
            <MenuItem
              key={key}
              onClick={handleMenuClick(onClick)}
              {...restProps}
            >
              {children}
            </MenuItem>
          ),
          menuItems,
        )}
      </Menu>
    </Box>
  );
}
