import { Stack, Typography } from "@mui/material";
import classNames from "classnames";
import { equals, isEmpty, isNil, isNotEmpty, map, not } from "ramda";
import { useEffect, useState } from "react";

import { L10n } from "@encoway/l10n";
import { ContainerTO, Viewport } from "@encoway/react-components";

import { Modal, ModalHeader } from "../../../components/modal/modal";
import { CONFIGURATOR_COMPONENTS } from "../../../constants";
import { useCart } from "../../../hooks/useCart";
import { useLvTable } from "../../../hooks/useLvTable";
import { hasRightToSeeLvHiddenViewport } from "../../../http/identityHelper";
import { ConfiguratorComponentProps } from "../../../types/configuration";
import { LvConfiguratorComponentProps } from "../../../types/lvTable";
import { LV_TAB_ID, LvTabId } from "../../../utilities/lvUtils";
import { LvConfiguratorDropdownField } from "../lvTable/components/LvConfiguratorDropdownField";
import { LvProductDropdown } from "../lvTable/components/LvProductDropdown";
import { LvConfiguratorTable } from "./LvConfiguratorTable";
import { LvImportTab } from "./LvImportTab";
import { LvOfferTab } from "./LvOfferTab";
import { LvTableTab } from "./LvTableTab";

type UploadLvModalProps = {
  onCancel: () => void;
};

export const UploadLvModal = ({ onCancel }: UploadLvModalProps) => {
  const {
    cart: {
      authorities: { booleanAuthorities },
    },
  } = useCart();
  const lvTable = useLvTable();
  const [selectedTab, setSelectedTab] = useState<LvTabId>(LV_TAB_ID.LV_IMPORT);

  const lvTabs = [
    {
      id: LV_TAB_ID.LV_IMPORT,
      name: "offer_management_create_lv_import_select_lv",
    },
    {
      id: LV_TAB_ID.LV_POSITIONS,
      name: "offer_management_create_lv_import_lv_positions",
      disabled: isEmpty(lvTable.lvPositions),
    },
    {
      id: LV_TAB_ID.LV_HEADER_DATA,
      name: "offer_management_create_lv_import_lv_header_data",
      disabled: not(lvTable.isAnyProductSelected) || isNil(lvTable.lvOldFile),
    },
  ];

  useEffect(() => {
    Viewport.register(
      CONFIGURATOR_COMPONENTS.DUMMY_VIEWPORT,
      LvConfiguratorTable,
    );
    Viewport.register(
      CONFIGURATOR_COMPONENTS.SECTION,
      (props: ConfiguratorComponentProps<ContainerTO>) =>
        props.data.parameters.map((parameter) =>
          Viewport.instance(parameter.viewPort!, {
            ...props,
            data: parameter,
            key: parameter.id,
          }),
        ),
    );
    Viewport.register(
      CONFIGURATOR_COMPONENTS.LV_DROPDOWN,
      LvConfiguratorDropdownField,
    );
    Viewport.register(
      CONFIGURATOR_COMPONENTS.LV_HIDDEN,
      (props: LvConfiguratorComponentProps) =>
        hasRightToSeeLvHiddenViewport(booleanAuthorities) ? (
          <LvConfiguratorDropdownField {...props} />
        ) : null,
    );
    Viewport.register(
      CONFIGURATOR_COMPONENTS.LV_PRODUCT_DROPDOWN,
      LvProductDropdown,
    );
  }, []);

  return (
    <Modal
      dialogId={"offer_management_create_lv_import"}
      className="fullwidth fullheight"
    >
      <ModalHeader label={L10n.format("offer_management_create_lv_import")}>
        {lvTable.lvFileName && (
          <Stack sx={{ marginTop: "-7px", marginBottom: "-7px" }}>
            <Typography sx={{ fontSize: "small", fontWeight: "bold" }}>
              {`${L10n.format("offer_management_create_lv_import_read_lv")}:`}
            </Typography>
            <Typography sx={{ fontSize: "small" }}>
              {lvTable.lvFileName}
            </Typography>
          </Stack>
        )}
      </ModalHeader>
      <div>
        <div className="tabs is-fullwidth mb-0">
          <ul
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(0, 1fr))",
            }}
          >
            {map(
              ({ id, name, disabled }) => (
                <li
                  key={id}
                  className={classNames({
                    "is-active": equals(selectedTab, id),
                    "is-disabled": disabled,
                  })}
                >
                  <a
                    {...(not(disabled) && {
                      onClick: () => setSelectedTab(id),
                    })}
                  >
                    {L10n.format(name)}
                  </a>
                </li>
              ),
              lvTabs,
            )}
          </ul>
        </div>
      </div>
      {/* could not render the elements conditionally, as this meant that the components always had to be reloaded
       when switching tabs. Nevertheless, dynamic rendering is required to reset the scroll state when changing PDFs or settings  */}
      <LvImportTab
        isHidden={not(equals(selectedTab, LV_TAB_ID.LV_IMPORT))}
        lvTable={lvTable}
        onCancel={onCancel}
        setSelectedTab={setSelectedTab}
      />
      {isNotEmpty(lvTable.lvPositions) && (
        <LvTableTab
          isHidden={not(equals(selectedTab, LV_TAB_ID.LV_POSITIONS))}
          lvTable={lvTable}
          onCancel={onCancel}
          setSelectedTab={setSelectedTab}
        />
      )}
      {lvTable.isAnyProductSelected && (
        <LvOfferTab
          isHidden={not(equals(selectedTab, LV_TAB_ID.LV_HEADER_DATA))}
          lvTable={lvTable}
          onCancel={onCancel}
        />
      )}
    </Modal>
  );
};
