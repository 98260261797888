import { AlertDialog } from "../../../../../../components/AlertDialog";
import { FlexTableCell } from "../../../../../../components/flexTable/flexTable";
import { hasRightToSeeBurgerMenuEntriesCart } from "../../../../../../http/identityHelper";
import { TeckentrupCartArticleTO } from "../../../../../../types/cart";
import { UiAuthorities } from "../../../../../../types/identity";
import { ItemState } from "../../../../../../utilities/cartUtils";
import { ItemRowMenu } from "../../../../../offerManagement/offerManagementTable/itemRowMenu";
import { BurgerMenuAction } from "../../../../../offerManagement/offerManagementTable/offerManagementTableUtils";
import { UseCartDropDownReturn } from "../../../../hooks/useCartDropDown";
import { t } from "../../../../localizationUtils";
import { DownloadNotPossibleModal } from "../../../modals/DownloadNotPossibleModal";
import { DownloadModal } from "../../../modals/downloadModal";
import { FreeItemModal } from "../../../modals/freeItemModal";
import { MessageModal } from "../../../modals/messageModal";
import { CartItemCommentModal } from "./cartItemCommentModal";
import { DeliveryTimeChangeModal } from "./deliveryTimeChangeModal";

function hasBurgerMenuAction(
  condition: boolean,
  burgerMenuAction: BurgerMenuAction,
) {
  return condition ? [burgerMenuAction] : [];
}

type DropDownCellProps = {
  unsavedCart: boolean;
  cartItem: TeckentrupCartArticleTO;
  itemRole: ItemState;
  authorities: UiAuthorities;
  cartDropDown: UseCartDropDownReturn;
};

export function DropDownCell({
  unsavedCart,
  cartItem,
  itemRole,
  authorities,
  cartDropDown,
}: Readonly<DropDownCellProps>) {
  const {
    toggle,
    showCommentModal,
    showDeleteModal,
    showEditModal,
    showChangeDeliveryTimeModal,
    showRequestDrawingModal,
    showRequestDrawingNoCartId,
    itemMessage,
    messageDetails,
    actions,
  } = cartDropDown;
  const {
    isGroupItem,
    isFreeItem,
    isConfigurableItem,
    isDrawingItem,
    isSparePartItem,
  } = itemRole;
  const rights = hasRightToSeeBurgerMenuEntriesCart(
    authorities.valueAuthorities,
  );

  const rowActions = [
    ...hasBurgerMenuAction(isConfigurableItem && rights.reconfigure, {
      action: actions.reconfigureCartItem(cartItem),
      icon: "schraubenschluessel",
      label: "cart_title_reconfigure",
    }),
    ...hasBurgerMenuAction(isConfigurableItem && rights.deliveryChange, {
      action: actions.toggleDeliveryChangeModal,
      icon: "reload",
      label: "cart_title_delivery_time_change",
    }),
    ...hasBurgerMenuAction(isFreeItem, {
      action: actions.toggleEditModal,
      icon: "schraubenschluessel",
      label: "cart_title_edit_position",
    }),
    ...hasBurgerMenuAction(isDrawingItem && rights.drawing, {
      action: actions.toggleRequestDrawingModal(unsavedCart),
      icon: "zeichnung-anfordern",
      label: "cart_title_get_drawing",
    }),
    ...hasBurgerMenuAction(!isGroupItem && rights.comment, {
      action: actions.toggleCommentModal,
      icon: "notiz",
      label: "cart_title_comment",
    }),
    ...hasBurgerMenuAction(!isGroupItem && !isSparePartItem && rights.copy, {
      action: actions.duplicateCartItem,
      icon: "position-duplizieren",
      label: "cart_title_copy_article",
    }),
    ...hasBurgerMenuAction(rights.delete, {
      action: actions.toggleDeleteModal,
      icon: "loeschen",
      label: "cart_title_delete",
    }),
  ];

  return (
    <FlexTableCell style={{ textAlign: "left" }}>
      <ItemRowMenu
        menuId={`dropdownCellButton-${cartItem.positionNr}`}
        actions={rowActions}
        toggle={toggle}
        toggleDropdown={actions.toggleDropDown}
      />
      <AlertDialog
        open={showDeleteModal}
        message={t(
          isGroupItem ? "cart_delete_folder_warn" : "cart_delete_warn",
          {
            article: cartItem.articleName,
            posNo: cartItem.positionNr,
          },
        )}
        onClose={actions.toggleDeleteModal}
        onSubmit={actions.deleteCartItem}
      />
      {showCommentModal && (
        <CartItemCommentModal
          onSubmit={actions.saveCartItemCommentModal(cartItem)}
          onCancel={actions.toggleCommentModal}
          comment={cartItem.comment}
        />
      )}
      {showEditModal && (
        <FreeItemModal
          cartItem={cartItem}
          toggleModal={actions.toggleEditModal}
          saveFreeItem={actions.saveCartItemEdit(cartItem)}
          isEditMode={true}
        />
      )}
      {showChangeDeliveryTimeModal && (
        <DeliveryTimeChangeModal
          cartItem={cartItem}
          onCancel={actions.toggleDeliveryChangeModal}
          onSave={actions.saveDeliveryTimeChangeModal}
        />
      )}
      {showRequestDrawingModal && (
        <DownloadModal
          closeDialogMethod={actions.toggleRequestDrawingModal(unsavedCart)}
          requestDrawingMethod={actions.requestDrawing}
          cartItem={cartItem}
        />
      )}
      {showRequestDrawingNoCartId && (
        <DownloadNotPossibleModal
          onCancel={actions.toggleRequestDrawingNoCartId}
          onSave={actions.toggleModifyOfferModal}
        />
      )}
      {itemMessage && (
        <MessageModal
          messageBoxTitle={messageDetails.title}
          messageLabel={messageDetails.text}
          saveAndCloseDialog={actions.toggleMessageModal}
        />
      )}
    </FlexTableCell>
  );
}
