import { HttpStatusCode } from "axios";
import { T, always, cond, curry, equals, gt, isNil, lt, path } from "ramda";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useConfiguration } from "../../../hooks/useConfiguration";
import { downloadDynamicDrawing } from "../../../service/drawingService";
import {
  CartActions,
  FreeArticleTO,
  TeckentrupCartArticleTO,
} from "../../../types/cart";
import {
  copyPositionEvent,
  deliveryTimeChangeEvent,
  reconfigureEvent,
  requestDrawingEvent,
  track,
} from "../../../utilities/eventTracking";
import { t } from "../localizationUtils";
import { CART_MODALS, CartModalsReturn } from "./useCartModals";
import { prepareFreeCartItem } from "./utils/cartDropDownUtils";

export const useCartDropDown = (
  cartItem: TeckentrupCartArticleTO,
  cartActions: CartActions,
  toggleModal: CartModalsReturn["modalActions"]["toggleModal"],
) => {
  const [toggle, setToggle] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showChangeDeliveryTimeModal, setShowChangeDeliveryTimeModal] =
    useState(false);
  const [showRequestDrawingModal, setShowRequestDrawingModal] = useState(false);
  const [showRequestDrawingNoCartId, setShowRequestDrawingNoCartId] =
    useState(false);
  const [itemMessage, setItemMessage] = useState(false);
  const [messageDetails, setMessageDetails] = useState({ title: "", text: "" });
  const [loading, setLoading] = useState(false);
  const { remove, configurationId, reconfiguration } = useConfiguration();
  const navigate = useNavigate();

  async function resolveRowWithLoading(fn: () => Promise<void>) {
    setLoading(true);
    await fn();
    setLoading(false);
  }

  const toggleDropDown = (dropDownActive?: boolean) =>
    setToggle((prev) => (!isNil(dropDownActive) ? dropDownActive : !prev));

  const toggleDeleteModal = () => {
    toggleDropDown(false);
    setShowDeleteModal((prev) => !prev);
  };

  const reconfigureCartItem = (cartItem: TeckentrupCartArticleTO) => () => {
    navigate(
      `/reconfiguration/product/${cartItem.articleNumber}/${cartItem.articleId}/${cartItem.positionNr}/${cartItem.articleOrderStatus}`,
    );
    track(reconfigureEvent);
  };

  const toggleDeliveryChangeModal = () => {
    toggleDropDown(false);
    setShowChangeDeliveryTimeModal((prev) => !prev);
  };

  const toggleEditModal = () => {
    toggleDropDown(false);
    setShowEditModal((prev) => !prev);
  };

  const toggleCommentModal = () => {
    toggleDropDown(false);
    setShowCommentModal((prev) => !prev);
  };

  const toggleRequestDrawingModal = (unsavedCart?: boolean) => () => {
    toggleDropDown(false);
    if (unsavedCart) {
      setShowRequestDrawingNoCartId(true);
      return;
    }
    setShowRequestDrawingModal((prev) => !prev);
  };

  const toggleRequestDrawingNoCartId = () => {
    toggleDropDown(false);
    setShowRequestDrawingNoCartId((prev) => !prev);
  };

  const toggleMessageModal = (details?: typeof messageDetails) => {
    const newMessageDetails = details || { title: "", text: "" };
    toggleDropDown(false);
    setItemMessage((prev) => !prev);
    setMessageDetails(newMessageDetails);
  };

  const saveCartItemEdit = curry(
    (
      { articleId, positionNr }: TeckentrupCartArticleTO,
      freeItem: FreeArticleTO,
    ) =>
      () => {
        resolveRowWithLoading(async () => {
          toggleEditModal();
          await cartActions.updateFreePosition(
            prepareFreeCartItem(freeItem),
            articleId,
          );
          await cartActions.setAmount({ articleId }, freeItem.amount);
        });
      },
  );

  const saveCartItemCommentModal = curry(
    (article: TeckentrupCartArticleTO, comment: string) => () => {
      resolveRowWithLoading(async () => {
        toggleCommentModal();
        await cartActions.setComment(article, comment);
      });
    },
  );

  const saveDeliveryTimeChangeModal = () => {
    resolveRowWithLoading(async () => {
      await cartActions.update(reconfiguration!.articleId, configurationId);
      await remove();
      setShowChangeDeliveryTimeModal(false);
      track(deliveryTimeChangeEvent);
    });
  };

  const deleteCartItem = () => {
    resolveRowWithLoading(async () => {
      await cartActions.removeCartItem(cartItem);
    });
  };

  const createMessageDetailsFromDrawingResponse = (status?: HttpStatusCode) => {
    const title = t("download_error_title");
    const text = cond([
      [equals(201), always(t("download_in_progress_text"))],
      [lt(299), always(`${t("download_error_text")} ${status}`)],
      [T, always("")],
    ])(status ?? 0);
    return { title, text };
  };

  const requestDrawing = (positionNumber: string, formats: string[]) => {
    resolveRowWithLoading(async () => {
      toggleRequestDrawingModal()();
      try {
        const { status } = await downloadDynamicDrawing(
          positionNumber,
          formats,
        );
        if (gt(status, 298) || equals(status, 201)) {
          toggleMessageModal(createMessageDetailsFromDrawingResponse(status));
        }
        track(requestDrawingEvent);
      } catch (error) {
        toggleMessageModal(
          createMessageDetailsFromDrawingResponse(
            path<HttpStatusCode>(["response", "status"], error),
          ),
        );
      }
    });
  };

  const toggleModifyOfferModal = () => {
    toggleRequestDrawingNoCartId();
    toggleModal(CART_MODALS.MODIFY_OFFER)();
  };

  const duplicateCartItem = () => {
    toggleDropDown();
    cartActions.duplicateArticle(cartItem);
    track(copyPositionEvent);
  };

  return {
    loading,
    toggle,
    showCommentModal,
    showDeleteModal,
    showEditModal,
    showChangeDeliveryTimeModal,
    showRequestDrawingModal,
    showRequestDrawingNoCartId,
    itemMessage,
    messageDetails,
    actions: {
      toggleDropDown,
      reconfigureCartItem,
      toggleDeliveryChangeModal,
      toggleEditModal,
      toggleCommentModal,
      toggleDeleteModal,
      toggleRequestDrawingModal,
      toggleRequestDrawingNoCartId,
      toggleMessageModal,
      toggleModifyOfferModal,
      saveDeliveryTimeChangeModal,
      saveCartItemCommentModal,
      saveCartItemEdit,
      requestDrawing,
      duplicateCartItem,
      deleteCartItem,
    },
  };
};

export type UseCartDropDownReturn = ReturnType<typeof useCartDropDown>;
