import { has } from "ramda";
import { useNavigate } from "react-router-dom";

import { useApp } from "../../../../hooks/useApp";
import { useCart } from "../../../../hooks/useCart";
import { ROUTES } from "../../../../http/constants";
import { ModifyOfferModal } from "../../../offerManagement/modifyOfferModal/modifyOfferModal";
import {
  CART_MODALS,
  CartModalsReturn,
  ModalData,
} from "../../hooks/useCartModals";
import { t } from "../../localizationUtils";
import { ValidateCartModal } from "../warnings/validateCartModal/validateCartModal";
import { PositionItemModal } from "./PositionItemModal";
import { ClearCartWarningModal } from "./clearCartWarningModal";
import { FreeItemModal } from "./freeItemModal";
import { MessageModal } from "./messageModal";
import { OrderFailedModal } from "./orderModal/orderFailedModal";
import { OrderModal } from "./orderModal/orderModal";
import { PrintOptionsModal } from "./printOptions/printOptionsModal";
import { ShareCartModal } from "./shareCartModal/shareCartModal";
import { UpdatePricesModal } from "./updatePricesModal/updatePricesModal";

type ModalsProps = {
  modals: CartModalsReturn["modals"];
  modalActions: CartModalsReturn["modalActions"];
  modalData: ModalData;
  isPartialOrder: boolean;
};

type ModalType = keyof CartModalsReturn["modals"];

export const Modals = (props: ModalsProps) => {
  const { modals, modalActions, modalData, isPartialOrder } = props;
  const { cart, cartActions } = useCart();
  const { companyId } = useApp();
  const navigate = useNavigate();
  const {
    saveQuoteModifyOfferModal,
    closeClearCartModalAndEmptyCart,
    saveFreeItemModal,
    toggleModal,
  } = modalActions;

  function isModalOpen(modalType: ModalType): boolean {
    return has(modalType, modals) && modals[modalType];
  }

  return (
    <div className="shopping-cart-modals">
      {isModalOpen(CART_MODALS.MODIFY_OFFER) && (
        <ModifyOfferModal
          companyId={companyId}
          closeDialogMethod={toggleModal(CART_MODALS.MODIFY_OFFER)}
          saveQuote={saveQuoteModifyOfferModal}
          quote={cart}
          isSaveAndNavigateButton={true}
        />
      )}

      {isModalOpen(CART_MODALS.CLEAR_CART) && (
        <ClearCartWarningModal
          onSubmit={closeClearCartModalAndEmptyCart}
          onCancel={toggleModal(CART_MODALS.CLEAR_CART)}
        />
      )}

      {isModalOpen(CART_MODALS.FREE_ITEM) && (
        <FreeItemModal
          toggleModal={toggleModal(CART_MODALS.FREE_ITEM)}
          saveFreeItem={saveFreeItemModal}
          isEditMode={false}
        />
      )}

      {isModalOpen(CART_MODALS.POSITION_ITEM) && (
        <PositionItemModal
          toggleModal={toggleModal(CART_MODALS.POSITION_ITEM)}
        />
      )}

      {isModalOpen(CART_MODALS.PRINT_OPTION) && (
        <PrintOptionsModal modalActions={modalActions} />
      )}

      {isModalOpen(CART_MODALS.ORDER_MODAL) && (
        <OrderModal
          closingMethod={toggleModal(CART_MODALS.ORDER_MODAL)}
          errorMethod={toggleModal(CART_MODALS.ORDER_FAILED_MODAL)}
          isPartialOrder={isPartialOrder}
        />
      )}

      {isModalOpen(CART_MODALS.ORDER_FAILED_MODAL) && (
        <OrderFailedModal
          closingMethod={toggleModal(CART_MODALS.ORDER_FAILED_MODAL)}
        />
      )}

      {isModalOpen(CART_MODALS.UPDATE_PRICES) && (
        <UpdatePricesModal
          toggleModal={toggleModal(CART_MODALS.UPDATE_PRICES)}
        />
      )}

      {isModalOpen(CART_MODALS.VALIDATE_CART) && <ValidateCartModal />}

      {isModalOpen(CART_MODALS.SHARE_CART) && (
        <ShareCartModal closingMethod={toggleModal(CART_MODALS.SHARE_CART)} />
      )}

      {isModalOpen(CART_MODALS.CART_ID_CHANGED_MODAL) && (
        <MessageModal
          messageBoxTitle={t("order_dialog_cart_id_changed_title")}
          messageLabel={t("order_dialog_cart_id_changed_content", {
            orderNumberOld: modalData?.orderNumberOld ?? "-",
            orderNumberNew: modalData?.orderNumberNew ?? "-",
          })}
          saveAndCloseDialog={() => {
            navigate(ROUTES.OFFER_MANAGEMENT);
            cartActions.getCart().then();
          }}
        />
      )}

      {isModalOpen(CART_MODALS.CART_ARTICLES_CHANGED_MODAL) && (
        <MessageModal
          messageBoxTitle={t("order_dialog_cart_articles_changed_title")}
          messageLabel={t("order_dialog_cart_articles_changed_content", {
            orderNumber: cart.orderNumber,
          })}
          saveAndCloseDialog={() =>
            cartActions
              .getCart()
              .then(() =>
                toggleModal(CART_MODALS.CART_ARTICLES_CHANGED_MODAL)(),
              )
          }
        />
      )}

      {isModalOpen(CART_MODALS.CART_DB_CHECK_MODAL) && (
        <MessageModal
          messageBoxTitle={t("db_check")}
          messageContent={modalData.content}
          saveAndCloseDialog={() =>
            cartActions
              .getCart()
              .then(() => toggleModal(CART_MODALS.CART_DB_CHECK_MODAL)())
          }
        />
      )}

      {isModalOpen(CART_MODALS.CART_DB_CHECK_MODAL_WARNING) && (
        <MessageModal
          messageBoxTitle={modalData.title}
          messageContent={modalData.content}
          saveAndCloseDialog={toggleModal(
            CART_MODALS.CART_DB_CHECK_MODAL_WARNING,
          )}
        />
      )}
    </div>
  );
};
