import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@mui/material";
import classNames from "classnames";
import { isEmpty, map, not } from "ramda";
import { ComponentProps, MouseEventHandler, useState } from "react";

import { InputMaxLength } from "../input/inputMaxLength";

type ModalInputDropdownProps = Readonly<
  ComponentProps<typeof InputMaxLength> & {
    options: string[];
    onMouseDown: MouseEventHandler<HTMLButtonElement>;
  }
>;

const StyledModalInputDropdown = styled("div")`
  input {
    padding-right: 49px;
    &:hover {
      border-color: #292929 !important;
      text-decoration: underline;
    }
  }
  .dropdown-trigger {
    width: 100%;
  }
  .dropdown-menu {
    translate: 0 -18px;
  }
`;

const DropdownIcon = styled("span")({
  height: "38px",
  width: "38px",
  color: "black",
  backgroundColor: "#a6c7c8",
  right: "0.68rem",
  bottom: "1rem",
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "none",
  border: "none",
  pointerEvents: "none",
  svg: {
    fontSize: "0.75em",
    height: "1em !important",
    width: "1em !important",
  },
});

export const ModalInputDropdown = ({
  options,
  onMouseDown,
  ...props
}: ModalInputDropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  if (isEmpty(options)) {
    return <InputMaxLength className="column-entry with-padding" {...props} />;
  }

  return (
    <StyledModalInputDropdown
      className={classNames("dropdown column-entry with-padding", {
        "is-active": isDropdownOpen,
      })}
    >
      <div className="dropdown-trigger">
        <InputMaxLength
          onBlur={() => setIsDropdownOpen(false)}
          onClick={() => setIsDropdownOpen((prev) => not(prev))}
          className="lv-input-dropdown"
          {...props}
        >
          <DropdownIcon>
            <FontAwesomeIcon
              className="icon is-small"
              icon={isDropdownOpen ? faAngleUp : faAngleDown}
            />
          </DropdownIcon>
        </InputMaxLength>
      </div>
      <div className="dropdown-menu with-padding">
        <div className="dropdown-content">
          {map(
            (option) => (
              <button
                key={option}
                value={option}
                className="dropdown-item"
                onMouseDown={onMouseDown}
              >
                {option}
              </button>
            ),
            options,
          )}
        </div>
      </div>
    </StyledModalInputDropdown>
  );
};
