import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

import { L10n } from "@encoway/l10n";

type AlertDialogProps = Readonly<{
  open: boolean;
  message: string;
  onClose: () => void;
  onSubmit: () => void;
}>;

export function AlertDialog({
  open,
  message,
  onClose,
  onSubmit,
}: AlertDialogProps) {
  function onSubmitWrapper() {
    onSubmit();
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="gray" onClick={onClose}>
          {L10n.format("no")}
        </Button>
        <Button onClick={onSubmitWrapper} autoFocus>
          {L10n.format("yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
