import { styled } from "@mui/material";
import { equals, findIndex, isNil, not, propEq } from "ramda";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import { v4 as uuidv4 } from "uuid";

import { L10n } from "@encoway/l10n";
import { Viewport } from "@encoway/react-components";

import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../../../components/modal/modal";
import { CONFIGURATOR_COMPONENTS } from "../../../../constants";
import { useCart } from "../../../../hooks/useCart";
import { useLvConfiguration } from "../../../../hooks/useLvConfiguration";
import { hasRightToSeeLvHiddenViewport } from "../../../../http/identityHelper";
import {
  ConfiguratorComponentProps,
  ContainerTO,
} from "../../../../types/configuration";
import {
  CompleteResolvedPosition,
  LvConfiguratorComponentProps,
  ResolvedPosition,
} from "../../../../types/lvTable";
import { isEmptyString } from "../../../../utilities/utilities";
import { LvConfiguratorTable } from "../../../offerManagement/lvModal/LvConfiguratorTable";
import { LvPositionView } from "../../../offerManagement/lvModal/LvPositionView";
import { LvConfiguratorDropdownField } from "../../../offerManagement/lvTable/components/LvConfiguratorDropdownField";
import { LvProductDropdown } from "../../../offerManagement/lvTable/components/LvProductDropdown";

type PositionItemModalProps = Readonly<{
  toggleModal: () => void;
}>;

const StyledModal = styled(Modal)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    width: "70% !important",
  },
}));

export function PositionItemModal({ toggleModal }: PositionItemModalProps) {
  const {
    cart: {
      authorities: { booleanAuthorities },
    },
    cartActions: { importLvPosition },
  } = useCart();
  const lvConfiguration = useLvConfiguration();
  const [lvPosition, setLvPosition] = useImmer<ResolvedPosition>({
    id: uuidv4(),
    lvPosition: "",
    lvReference: "",
    lvResolvedReference: "",
    lvResolvedText: "",
    lvText: "",
    lvType: "position",
  });
  function updatePosition(_: string, newPos: Partial<ResolvedPosition>) {
    setLvPosition((draft) => ({ ...draft, ...newPos }));
  }

  function updatePositionParameter(
    _: string,
    parameterName: string,
    parameterValue: string,
  ) {
    setLvPosition((draft) => {
      const { lvConfigurationParameters } = draft;
      if (isNil(lvConfigurationParameters)) {
        return;
      }
      const parameterIndex = findIndex(
        propEq(parameterName, "name"),
        lvConfigurationParameters,
      );
      if (not(equals(parameterIndex, -1))) {
        lvConfigurationParameters[parameterIndex].value = parameterValue;
      }
    });
  }

  async function onSavePosition() {
    toggleModal();
    importLvPosition(lvPosition as CompleteResolvedPosition);
  }

  useEffect(() => {
    Viewport.register(
      CONFIGURATOR_COMPONENTS.DUMMY_VIEWPORT,
      LvConfiguratorTable,
    );
    Viewport.register(
      CONFIGURATOR_COMPONENTS.SECTION,
      (props: ConfiguratorComponentProps<ContainerTO>) =>
        props.data.parameters.map((parameter) =>
          Viewport.instance(parameter.viewPort!, {
            ...props,
            data: parameter,
            key: parameter.id,
          }),
        ),
    );
    Viewport.register(
      CONFIGURATOR_COMPONENTS.LV_DROPDOWN,
      LvConfiguratorDropdownField,
    );
    Viewport.register(
      CONFIGURATOR_COMPONENTS.LV_HIDDEN,
      (props: LvConfiguratorComponentProps) =>
        hasRightToSeeLvHiddenViewport(booleanAuthorities) ? (
          <LvConfiguratorDropdownField {...props} />
        ) : null,
    );
    Viewport.register(
      CONFIGURATOR_COMPONENTS.LV_PRODUCT_DROPDOWN,
      LvProductDropdown,
    );
  }, []);

  return (
    <StyledModal dialogId="add-lv-position">
      <ModalHeader
        label={L10n.format("add_lv_position")}
        onCancel={toggleModal}
      >
        <span className="modal-card-notice">
          {L10n.format("position_item_dialog_head_notice")}
        </span>
      </ModalHeader>
      <ModalBodyWrapper className="p-0">
        <LvPositionView
          updatePosition={updatePosition}
          updatePositionParameter={updatePositionParameter}
          selectedPosition={lvPosition}
          lvConfiguration={lvConfiguration}
          isImportSingleItem={true}
        />
      </ModalBodyWrapper>
      <ModalFooter
        saveLabel={L10n.format("lv_position_action_add_position")}
        saveButtonId={"create_lv_item_position"}
        cancelLabel={L10n.format("cancel")}
        onCancel={toggleModal}
        onSave={onSavePosition}
        disabled={isEmptyString(lvPosition.product?.value)}
      />
    </StyledModal>
  );
}
