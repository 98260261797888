import { Box, Stack, styled } from "@mui/material";
import classNames from "classnames";
import { gte, isEmpty, isNil, isNotNil } from "ramda";
import { ChangeEvent, useReducer, useState } from "react";

import { L10n } from "@encoway/l10n";

import { AlertDialog } from "../../../components/AlertDialog";
import { NumberInput } from "../../../components/input/NumberInput";
import { TextInput } from "../../../components/input/TextInput";
import { LvConfiguration } from "../../../hooks/useLvConfiguration";
import { ResolvedPosition } from "../../../types/lvTable";
import {
  concatReferenceString,
  initializeLvPosition,
} from "../../../utilities/lvUtils";
import { Input } from "../../shoppingCart/components/modals/orderModal/input";
import { t } from "../../shoppingCart/localizationUtils";
import { LvProductField } from "../lvTable/components/LvProductField";

const PANEL_SIZE_SIDE_BY_SIDE = 80;

export const SmallBlackLabel = styled("label")({
  display: "block",
  color: "#000000",
  marginBottom: "0.5em",
  fontWeight: "bold",
});

const EditableTextArea = styled("textarea")({
  height: "40dvh",
  resize: "none",
  fontSize: "small",
  lineHeight: "2.5em",
});

const EmbeddedHtml = styled("div")({
  height: "40dvh",
  overflow: "auto",
  border: "1px solid rgb(219, 219, 219)",
  fontSize: "small",
  padding: "calc(0.75em - 1px)",
  figure: {
    marginBottom: "0 !important",
    "mark.entity": {
      background: "#adc6c9 !important",
    },
  },
});

const TextAreaToggleLabel = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const LabelButton = styled("button")({
  background: "none",
  border: "none",
  margin: "0",
  fontSize: "small",
  color: "#000000",
  padding: 0,
  marginBottom: "0.5em",
  "&:hover": {
    cursor: "pointer",
    textDecoration: "underline",
  },
});

type LvPositionHeaderProps = Readonly<{
  selectedPosition: ResolvedPosition;
  updatePosition: (id: string, newPos: Partial<ResolvedPosition>) => void;
  lvConfiguration: LvConfiguration;
  positionMenu?: JSX.Element;
  isImportSingleItem?: boolean;
}>;

function LvPositionHeader({
  selectedPosition,
  updatePosition,
  lvConfiguration,
  positionMenu,
  isImportSingleItem,
}: LvPositionHeaderProps) {
  const { isLoading, setIsLoading, startConfAndSetValues } = lvConfiguration;
  const [showAnalyzeWarning, setShowAnalyzeWarning] = useState(false);

  async function analyzePosition() {
    setIsLoading(true);
    const initializedPosition = await initializeLvPosition(selectedPosition);
    await startConfAndSetValues(initializedPosition.lvParameters);
    updatePosition(selectedPosition.id, initializedPosition);
    setIsLoading(false);
  }

  async function onClickAnalyze() {
    if (isLoading || isEmpty(selectedPosition.lvText)) {
      return;
    }
    // ckeck one of the properties that are added via analyzePosition
    if (isNotNil(selectedPosition.lvQuantity)) {
      setShowAnalyzeWarning(true);
      return;
    }
    analyzePosition();
  }

  return (
    <Box sx={{ display: "flex", gap: 2, justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        {isImportSingleItem ? (
          <div className="content">{t("position_item_dialog_subtitle")}</div>
        ) : (
          <>
            <label style={{ fontWeight: "bold", textWrap: "nowrap" }}>
              {t("lv_position_label")}
            </label>
            <TextInput
              className="input"
              style={{ minWidth: "80px" }}
              value={selectedPosition.lvPosition}
              setValue={(lvPosition) =>
                updatePosition(selectedPosition.id, { lvPosition })
              }
              data-input-field-id="lv-position"
            />
          </>
        )}
      </Box>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <button
          className={classNames("button is-info is-blue-dark", {
            "is-loading": isLoading,
          })}
          onClick={onClickAnalyze}
          disabled={isEmpty(selectedPosition.lvText)}
        >
          {t("lv_position_analyze")}
        </button>
        <AlertDialog
          open={showAnalyzeWarning}
          message={t("lv_warning_analyze_reset_configuration")}
          onClose={() => setShowAnalyzeWarning(false)}
          onSubmit={analyzePosition}
        />
        {positionMenu}
      </Box>
    </Box>
  );
}

type LvPositionTextAreaProps = Readonly<{
  sideBySide: boolean;
  lvText: string;
  lvTextAnalyzed?: string;
  onInputChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  isImportSingleItem: boolean;
}>;

function LvPositionTextArea({
  sideBySide,
  lvText,
  lvTextAnalyzed,
  onInputChange,
  isImportSingleItem,
}: LvPositionTextAreaProps) {
  const [showAnalyzedText, toggleShowAnalyzedText] = useReducer(
    (prev) => !prev,
    true,
  );

  const textAreaHeaderKey = isImportSingleItem
    ? "lv_import_textarea_your_text"
    : "lv_import_textarea_original_label";

  if (isNil(lvTextAnalyzed)) {
    return (
      <Box>
        <SmallBlackLabel>{t(textAreaHeaderKey)}</SmallBlackLabel>
        <EditableTextArea
          name="comment"
          className="textarea"
          value={lvText}
          onChange={onInputChange}
        />
      </Box>
    );
  }

  if (sideBySide) {
    return (
      <Stack direction="row" spacing={2}>
        <Box sx={{ flex: 1 }}>
          <SmallBlackLabel>{t(textAreaHeaderKey)}</SmallBlackLabel>
          <EditableTextArea
            name="comment"
            className="textarea"
            value={lvText}
            onChange={onInputChange}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <SmallBlackLabel>
            {t("lv_import_textarea_analyzed_label")}
          </SmallBlackLabel>
          <EmbeddedHtml
            dangerouslySetInnerHTML={{
              __html: lvTextAnalyzed,
            }}
          />
        </Box>
      </Stack>
    );
  }

  if (showAnalyzedText) {
    return (
      <Box>
        <TextAreaToggleLabel>
          <SmallBlackLabel>
            {t("lv_import_textarea_analyzed_label")}
          </SmallBlackLabel>
          <LabelButton onClick={toggleShowAnalyzedText}>
            {t("lv_import_show_original_text")}
          </LabelButton>
        </TextAreaToggleLabel>
        <EmbeddedHtml
          dangerouslySetInnerHTML={{
            __html: lvTextAnalyzed,
          }}
        />
      </Box>
    );
  }

  return (
    <Box>
      <TextAreaToggleLabel>
        <SmallBlackLabel>
          {t("lv_import_textarea_original_label")}
        </SmallBlackLabel>
        <LabelButton onClick={toggleShowAnalyzedText}>
          {t("lv_import_show_analyzed_text")}
        </LabelButton>
      </TextAreaToggleLabel>
      <EditableTextArea
        name="comment"
        className="textarea"
        value={lvText}
        onChange={onInputChange}
      />
    </Box>
  );
}

type LvPositionAnalyzedTextProps = Readonly<{
  selectedPosition: ResolvedPosition;
  updatePosition: (id: string, newPos: Partial<ResolvedPosition>) => void;
  sideBySide: boolean;
  isImportSingleItem: boolean;
}>;

function LvPositionAnalyzedText({
  selectedPosition,
  updatePosition,
  sideBySide,
  isImportSingleItem,
}: LvPositionAnalyzedTextProps) {
  const {
    id,
    lvReference,
    lvResolvedReference,
    lvQuantity,
    lvText,
    lvAnalyzedText,
  } = selectedPosition;

  const [inputReference, setInputReference] = useState("");

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Box sx={{ flex: 1 }}>
          <SmallBlackLabel>
            {L10n.format("lv_import_position_reference")}
          </SmallBlackLabel>
          {isImportSingleItem ? (
            <Input
              value={inputReference}
              onChange={({ target: { value } }) => setInputReference(value)}
              onBlur={() => updatePosition(id, { lvReference: inputReference })}
            />
          ) : (
            <Input
              value={concatReferenceString(
                lvReference,
                lvResolvedReference,
                "-",
              )}
              disabled
            />
          )}
        </Box>
        <Box sx={{ flex: 1 }}>
          <SmallBlackLabel>
            {L10n.format("lv_import_position_amount")}
          </SmallBlackLabel>
          {isNil(lvQuantity) ? (
            <Input value={"-"} disabled />
          ) : (
            <NumberInput
              className="input"
              value={lvQuantity}
              setValue={(lvQuantity) => updatePosition(id, { lvQuantity })}
              data-input-field-id="lv-amount"
            />
          )}
        </Box>
      </Stack>
      <LvPositionTextArea
        sideBySide={sideBySide}
        lvText={lvText}
        lvTextAnalyzed={lvAnalyzedText}
        onInputChange={({ target }) =>
          updatePosition(id, { lvText: target.value })
        }
        isImportSingleItem={isImportSingleItem}
      />
    </>
  );
}

type LvPositionViewProps = Readonly<{
  updatePosition: (id: string, newPos: Partial<ResolvedPosition>) => void;
  updatePositionParameter: (
    positionId: string,
    parameterName: string,
    parameterValue: string,
  ) => void;
  selectedPosition: ResolvedPosition;
  lvConfiguration: LvConfiguration;
  panelSize?: number;
  positionMenu?: JSX.Element;
  isImportSingleItem?: boolean;
}>;

export function LvPositionView({
  updatePosition,
  updatePositionParameter,
  selectedPosition,
  lvConfiguration,
  panelSize,
  positionMenu,
  isImportSingleItem = false,
}: LvPositionViewProps) {
  const sideBySide = panelSize ? gte(panelSize, PANEL_SIZE_SIDE_BY_SIDE) : true;

  return (
    <Stack spacing={5} sx={{ padding: 2, paddingBottom: 5 }}>
      <Stack spacing={3}>
        <LvPositionHeader
          selectedPosition={selectedPosition}
          updatePosition={updatePosition}
          lvConfiguration={lvConfiguration}
          positionMenu={positionMenu}
          isImportSingleItem={isImportSingleItem}
        />
        <LvPositionAnalyzedText
          selectedPosition={selectedPosition}
          updatePosition={updatePosition}
          sideBySide={sideBySide}
          isImportSingleItem={isImportSingleItem}
        />
      </Stack>
      <LvProductField
        selectedPosition={selectedPosition}
        updatePosition={updatePosition}
        updatePositionParameter={updatePositionParameter}
        lvConfiguration={lvConfiguration}
      />
    </Stack>
  );
}
