import classNames from "classnames";
import { isEmpty, length, not } from "ramda";
import {
  ChangeEventHandler,
  ComponentPropsWithoutRef,
  FocusEvent,
  useEffect,
  useRef,
  useState,
} from "react";

import { L10n } from "@encoway/l10n";

import { isPostCodeMaxReached } from "../../pages/shoppingCart/components/modals/differingShippingAddressUtils";
import { Input } from "../../pages/shoppingCart/components/modals/orderModal/input";
import { t } from "../../pages/shoppingCart/localizationUtils";

import "./inputMaxLength.scss";

type InputMaxLengthProps = Pick<
  ComponentPropsWithoutRef<"input">,
  | "readOnly"
  | "className"
  | "name"
  | "onBlur"
  | "placeholder"
  | "maxLength"
  | "children"
  | "onClick"
> & {
  isInputValid?: boolean;
  hasWarning?: boolean;
  warningLabel?: string;
  warningDuration?: number;
  label?: string;
  inputId: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

export const InputMaxLength = ({
  isInputValid = true,
  maxLength,
  hasWarning = false,
  warningLabel = "",
  warningDuration = 3000,
  value = "",
  inputId,
  onChange,
  onBlur,
  children,
  className,
  ...props
}: InputMaxLengthProps) => {
  const [activeInputWarning, setActiveInputWarning] = useState({
    msg: "",
    toggle: false,
  });
  const initialValueRef = useRef(value);

  function closeInputWarning() {
    setActiveInputWarning({ ...activeInputWarning, toggle: false });
  }

  function isValid() {
    return (
      isInputValid ||
      !hasWarning ||
      isEmpty(value) ||
      isPostCodeMaxReached(maxLength ?? 10, value)
    );
  }

  function openAndCloseInputWarning(inputWarning: typeof activeInputWarning) {
    setActiveInputWarning(inputWarning);
    setTimeout(() => {
      closeInputWarning();
    }, warningDuration);
  }

  function onBlurValue(event: FocusEvent<HTMLInputElement>) {
    if (not(isInputValid) && hasWarning && value !== initialValueRef.current) {
      openAndCloseInputWarning({ msg: t(warningLabel), toggle: true });
      return;
    }
    initialValueRef.current = value;
    onBlur?.(event);
  }

  useEffect(() => {
    if (!isValid()) {
      openAndCloseInputWarning({ msg: t(warningLabel), toggle: true });
    } else {
      activeInputWarning.toggle && closeInputWarning();
    }
  }, [hasWarning, isInputValid]);

  return (
    <div className={classNames(className, { "has-input-warning": hasWarning })}>
      <Input
        value={value}
        onChange={onChange}
        onBlur={onBlurValue}
        maxLength={maxLength}
        inputId={inputId}
        {...props}
      />
      {children}
      {activeInputWarning.toggle && (
        <button
          className="inputWarning"
          onClick={closeInputWarning}
          style={{ border: 0 }}
        >
          {activeInputWarning.msg}
        </button>
      )}
      {maxLength && (
        <p className="with-padding charcount is-grey" data-label-id={inputId}>
          {`${length(value)}/${maxLength} ${L10n.format("chars_used")}`}
        </p>
      )}
    </div>
  );
};
