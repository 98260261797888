import { styled } from "@mui/material";
import classNames from "classnames";
import { head, isEmpty, isNotEmpty } from "ramda";
import { useEffect, useState } from "react";

import { L10n } from "@encoway/l10n";

import { ProgressTable } from "../../../components/ProgressTable";
import { ModalInputDropdown } from "../../../components/modal/ModalInputDropdown";
import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../../components/modal/modal";
import { TextArea } from "../../../components/textarea";
import { LvTable } from "../../../hooks/useLvTable";
import { LvConstructionAndAddressData } from "../../../types/lvTable";
import { mergeLeftPersonAndAddress } from "../../../utilities/lvUtils";
import { defaultToString } from "../../../utilities/utilities";
import { InputConstants } from "../modifyOfferModal/inputConstants";
import { LvModalFooter } from "./LvModalFooter";
import { LvOfferAddress } from "./LvOfferAddress";
import { useOfferCreate } from "./useOfferCreate";

export type LvAddressData = {
  customerName: string;
  title: string;
  firstname: string;
  surname: string;
  street: string;
  houseNumber: string;
  zip: string;
  city: string;
  phone: string;
  email: string;
};

const LvModalBodyWrapper = styled(ModalBodyWrapper)({
  ".label, .quote-address-salutation-label": {
    color: "#000000",
  },

  ".quote-address-salutation-label": {
    padding: 0,
    paddingTop: "0.5em",
    marginBottom: "0.5em",
  },

  ".quote-address-salutation-wrapper": {
    padding: 0,
  },

  ".quote-address-salutation.active": {
    backgroundColor: "#adc6c9",
  },

  ".quote-address-salutation-bg, button": {
    borderColor: "#adc6c9",
  },

  "textarea, input": {
    ":not(:focus):not(:hover)": {
      borderColor: "#adc6c9 !important",
    },
    borderColor: "#adc6c9 !important",
  },

  ".generic-dropdown .dropdown-trigger .button .icon.is-small.has-text-orange":
    {
      backgroundColor: "#a6c7c8",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      height: "40px",
      width: "40px",
      color: "black",
    },

  ".dropdown-trigger .button": {
    paddingRight: "0.5rem",
  },
});

function getInitialAddressData(
  addresses: LvConstructionAndAddressData["addresses"],
  contactPersons: LvConstructionAndAddressData["contactPersons"],
) {
  return mergeLeftPersonAndAddress(head(contactPersons), head(addresses));
}

type LvOfferTabProps = {
  isHidden: boolean;
  lvTable: LvTable;
  onCancel: () => void;
};

export const LvOfferTab = ({
  isHidden,
  lvTable,
  onCancel,
}: LvOfferTabProps) => {
  const {
    constructionAndAddressData: {
      construction,
      reference,
      addresses,
      contactPersons,
    },
  } = lvTable;
  const [buildProject, setBuildProject] = useState(
    defaultToString(head(construction)),
  );
  const [referenceNumber, setReferenceNumber] = useState(
    defaultToString(head(reference)),
  );
  const [comment, setComment] = useState("");
  const [addressData, setAddressData] = useState(
    getInitialAddressData(addresses, contactPersons),
  );
  const {
    createAndOpenOffer,
    showLoadingModal,
    closeLoadingModal,
    offerCreateState,
  } = useOfferCreate(
    lvTable,
    buildProject,
    referenceNumber,
    comment,
    addressData,
  );

  useEffect(() => {
    setBuildProject(defaultToString(head(construction)));
    setReferenceNumber(defaultToString(head(reference)));
    setAddressData(getInitialAddressData(addresses, contactPersons));
  }, [construction, reference, addresses, contactPersons]);

  return (
    <>
      <LvModalBodyWrapper className={classNames({ "is-hidden": isHidden })}>
        <div className="two-columns mb-2">
          <ModalInputDropdown
            inputId="lv_building_project"
            name="lvBuildProject"
            label={
              L10n.format("building_project") + L10n.format("mandatory_symbol")
            }
            placeholder={L10n.format("building_project")}
            value={buildProject}
            onChange={({ target }) => setBuildProject(target.value)}
            maxLength={InputConstants.length.building_project}
            options={construction}
            onMouseDown={({ currentTarget }) =>
              setBuildProject(currentTarget.value)
            }
          />
          <ModalInputDropdown
            inputId="lv_reference_order_id"
            name="lvReferenceNumber"
            label={
              L10n.format("reference_order_id") +
              L10n.format("mandatory_symbol")
            }
            placeholder={L10n.format("reference_order_id")}
            value={referenceNumber}
            onChange={({ target }) => setReferenceNumber(target.value)}
            maxLength={InputConstants.length.modify_dialog_reference}
            options={reference}
            onMouseDown={({ currentTarget }) =>
              setReferenceNumber(currentTarget.value)
            }
          />
        </div>
        <TextArea
          label={L10n.format("comment")}
          value={comment}
          onInputChange={({ target }) => setComment(target.value)}
          attributeId="lv_comment"
        />
        <LvOfferAddress
          addresses={addresses}
          contactPersons={contactPersons}
          addressData={addressData}
          setAddressData={setAddressData}
        />
        <span className="smallText with-padding">
          {L10n.format("mandatory_symbol_information")}
        </span>
      </LvModalBodyWrapper>
      <LvModalFooter
        className={classNames({ "is-hidden": isHidden })}
        cancelLabel={L10n.format("cancel")}
        saveLabel={L10n.format("offer_management_lv_create_new_offer_title")}
        saveButtonId="save_import"
        onCancel={onCancel}
        onSave={createAndOpenOffer}
        disabled={isEmpty(buildProject) || isEmpty(referenceNumber)}
        saveIcon={
          <i
            className="image-icon neuen-vorgang-erstellen"
            aria-hidden="true"
          />
        }
      />
      {showLoadingModal && (
        <Modal removeClipped={false}>
          <ModalHeader
            label={L10n.format("offer_management_lv_create_new_offer_title")}
            onCancel={closeLoadingModal}
          />
          <ModalBodyWrapper
            label={L10n.format("offer_management_lv_create_new_offer_body")}
            className="is-flex is-flex-direction-column gap-3"
          >
            <ProgressTable
              tableHeader={{ description: L10n.format("description") }}
              tableCells={[
                {
                  isLoading: offerCreateState.isCreateLoading,
                  isSuccess: offerCreateState.isCreateSuccess,
                  description: L10n.format(
                    "offer_management_lv_create_new_offer_validate",
                  ),
                },
                {
                  isLoading: offerCreateState.isCreateLoading,
                  isSuccess: offerCreateState.isCreateSuccess,
                  description: L10n.format(
                    "offer_management_lv_create_new_offer_create",
                  ),
                },
                {
                  isLoading: offerCreateState.isOpenLoading,
                  isSuccess: offerCreateState.isOpenSuccess,
                  description: L10n.format(
                    "offer_management_lv_create_new_offer_open",
                  ),
                },
              ]}
            />
            {isNotEmpty(offerCreateState.errorMessage) && (
              <p className="has-text-danger with-padding">
                {L10n.format(offerCreateState.errorMessage)}
              </p>
            )}
          </ModalBodyWrapper>
          <ModalFooter
            cancelLabel={L10n.format("cancel")}
            onCancel={closeLoadingModal}
            saveLabel={L10n.format("ok")}
            disabled={
              offerCreateState.isCreateLoading || offerCreateState.isOpenLoading
            }
          />
        </Modal>
      )}
    </>
  );
};
