import { produce } from "immer";
import {
  T,
  any,
  assoc,
  compose,
  cond,
  defaultTo,
  equals,
  filter,
  find,
  findIndex,
  gt,
  gte,
  has,
  head,
  identity,
  includes,
  isEmpty,
  isNil,
  isNotEmpty,
  join,
  length,
  lte,
  map,
  multiply,
  pathOr,
  propEq,
  reduce,
  reject,
  split,
  trim,
} from "ramda";
import { v4 as uuidv4 } from "uuid";

import { LvConfiguration } from "../hooks/useLvConfiguration";
import { LvTable } from "../hooks/useLvTable";
import IdentityStore from "../http/identityStore";
import { getSelectableDropDownValues } from "../pages/configurator/customizing/configuratorDropDownUtils";
import {
  COUNTRY_AVAILABILITY,
  ROLE_AVAILABILITY,
} from "../pages/products/productSelectionUtils";
import { analyzePosition } from "../service/lvService";
import { ParameterTO } from "../types/@encoway/Parameter";
import { SelectionSource } from "../types/@encoway/Value";
import { ContainerTO, CreatedConfig } from "../types/configuration";
import {
  LvAddress,
  LvBase,
  LvBaseExtended,
  MinimalParameter,
  ResolvedPosition,
} from "../types/lvTable";
import { isEmptyString, mapIndexed } from "./utilities";

export const LV_TAB_ID = {
  LV_IMPORT: "lvImport",
  LV_POSITIONS: "lvPositions",
  LV_HEADER_DATA: "lvHeaderData",
} as const;

export type LvTabId = (typeof LV_TAB_ID)[keyof typeof LV_TAB_ID];

// Standard height           Standard width (1. flg)         Standard width (2. flg)
// 1.90 m  - 2.20 m          0.61 m   - 0.99 m               1.23 m  - 1.99 m
// 190 cm  - 220 cm            61 cm  -   99 cm              123 cm  - 199 cm
// 1900 mm - 2200 mm           610 mm -   990 mm             1230 mm - 1990 mm
const METERS_MAX = 4;
const CENTIMETERS_MAX = 400;

const PARAMETER_NAME_MAP: Record<string, string | undefined> = {
  staerkeTuerblatt: "inputStaerkeTuerblatt",
  T_M_RS: "inputRauchschutz",
};

const CONFIGURATION_CHARACTERISTICS = [
  "T_M_EINGMA",
  "T_M_BMB",
  "T_M_BMH",
  "T_M_DINR",
  "T_M_ZARGE",
  "T_M_MAULW",
  "T_M_EN1125",
  "T_M_PAFKT",
];

export const parseQuantity = (quantityValue: string) => {
  const amount = isEmpty(quantityValue)
    ? "1"
    : split(",", quantityValue)[0].replaceAll(/\D/g, "");
  return Number(amount);
};

const parseDimension = (value: string) =>
  Number(value.replace(",", ".").replaceAll(/[a-zA-Z\s]/g, ""));

const convertDimension = cond<number[], number>([
  [gt(METERS_MAX), multiply(1000)],
  [gt(CENTIMETERS_MAX), multiply(10)],
  [T, identity],
]);

export const prepareParameters = map<LvBase, LvBase>((lvParameter) => {
  if (
    equals(lvParameter.name, "T_M_BMB") ||
    equals(lvParameter.name, "T_M_BMH")
  ) {
    const parsedValue = parseDimension(lvParameter.value);
    const absoluteValue = Math.abs(parsedValue);
    const convertedValue = Math.round(convertDimension(absoluteValue));
    return {
      ...lvParameter,
      value: String(convertedValue),
    };
  }
  return lvParameter;
});

function getParameters(
  container: ContainerTO,
  parameterMap: Map<ParameterTO["name"], ParameterTO>,
) {
  for (const parameter of container.parameters) {
    parameterMap.set(parameter.name, parameter);
  }
  for (const child of container.children) {
    getParameters(child, parameterMap);
  }
}

function getParameterMap(container: ContainerTO) {
  const parameterMap = new Map<ParameterTO["name"], ParameterTO>();
  getParameters(container, parameterMap);
  return parameterMap;
}

function anyAffectedParameterNotSetByDefault(error: unknown) {
  return any(
    propEq(false, "setByDefault"),
    pathOr([], ["response", "affectedParameters"], error),
  );
}

type ChangeParameter = {
  name: string;
  value: string | undefined;
};

async function tryParameterChanges(
  cs: CreatedConfig,
  parameterMap: Map<ParameterTO["name"], ParameterTO>,
  parameters: ChangeParameter[],
) {
  // for of loop, as changes must not be made in parallel
  for (const { name, value } of parameters) {
    try {
      const parameterId = parameterMap.get(name)?.id;
      if (isEmptyString(value) || isNil(parameterId)) {
        continue;
      }
      await cs.force(parameterId, value, "UNFORMATTED");
    } catch (error) {
      console.error("Error on cs.force:", name, value, error);
      if (anyAffectedParameterNotSetByDefault(error)) {
        await cs
          .decline()
          .catch((declineError) =>
            console.error("Error on cs.decline:", name, value, declineError),
          );
        console.info("Declined force for cs.force error:", name, value);
      }
    }
  }
}

function prepareParameterName<T extends { name: string }>(lvParameter: T) {
  return assoc(
    "name",
    pathOr(lvParameter.name, [lvParameter.name], PARAMETER_NAME_MAP),
    lvParameter,
  );
}

const joinRoles = compose(
  join(";"),
  map((role) => `"${role}"`),
);

function prepareRoleValue(roles: string[]) {
  if (lte(length(roles), 1)) {
    return head(roles);
  }
  return `{${joinRoles(roles)}}`;
}

// The following sequence must be followed: role availability, country availability, all others
export async function setConfigValues(
  cs: CreatedConfig,
  parameters: { name: string; value: string }[],
  identityStore: IdentityStore,
  product?: MinimalParameter,
) {
  const guiTO = await cs.ui();
  const parameterMap = getParameterMap(guiTO.rootContainer);
  const preparedParameters = map(prepareParameterName, parameters);
  const allParameters = [
    {
      name: ROLE_AVAILABILITY,
      value: prepareRoleValue(defaultTo([], identityStore.getRoles())),
    },
    {
      name: COUNTRY_AVAILABILITY,
      value: identityStore.getIdentity()?.FirmadesNutzers.FirmenLand,
    },
    ...preparedParameters,
  ];
  if (product) {
    allParameters.push({ name: product.name, value: product.value });
  }
  await tryParameterChanges(cs, parameterMap, allParameters);
}

const buildProduct = (
  doors: ParameterTO | undefined,
): MinimalParameter & { selectableCount: number } => ({
  name: "doors",
  value: pathOr("", ["selectedValues", 0, "value"], doors),
  translatedValue: pathOr("", ["selectedValues", 0, "translatedValue"], doors),
  selectionSource: pathOr<SelectionSource>(
    "NOT_SET",
    ["selectedValues", 0, "selectionSource"],
    doors,
  ),
  selectableCount: length(
    getSelectableDropDownValues(pathOr([], ["values"], doors)),
  ),
});

const buildParameters = (
  parameterMap: Map<ParameterTO["name"], ParameterTO>,
): MinimalParameter[] =>
  map(
    ({ name, selectedValues }) => ({
      name,
      value: pathOr("", [0, "value"], selectedValues),
      translatedValue: pathOr("", [0, "translatedValue"], selectedValues),
      selectionSource: pathOr<SelectionSource>(
        "NOT_SET",
        [0, "selectionSource"],
        selectedValues,
      ),
    }),
    Array.from(parameterMap.values()),
  );

export async function getGuiParameters(cs: CreatedConfig) {
  const guiTO = await cs.ui();
  const parameterMap = getParameterMap(guiTO.rootContainer);
  const doors = parameterMap.get("doors");
  const product = buildProduct(doors);
  parameterMap.delete("doors");
  const parameters = buildParameters(parameterMap);
  return { parameters, product };
}

export function isOriginalValue(
  originalValue: string | undefined,
  value: string,
) {
  if (isEmptyString(originalValue)) {
    return false;
  }
  return equals(originalValue, value);
}

const joinOnSpaceAndTrim = compose(trim, join(" "));

export const getAddressDetails = (option: LvAddress) => {
  const details = [
    option.addressIdentifier,
    joinOnSpaceAndTrim([option.street, option.houseNumber]),
    joinOnSpaceAndTrim([option.zip, option.city]),
    option.email,
    option.phone,
  ];
  const filteredDetails = reject(isEmpty, details);
  return join(" | ", filteredDetails);
};

export const getPersonDetails = (option: LvAddress) => {
  const details = [
    option.company,
    joinOnSpaceAndTrim([option.salutation, option.firstname, option.surname]),
    joinOnSpaceAndTrim([option.street, option.houseNumber]),
    joinOnSpaceAndTrim([option.zip, option.city]),
    option.email,
    option.phone,
  ];
  const filteredDetails = reject(isEmpty, details);
  return join(" | ", filteredDetails);
};

function getLvAddressProperty(
  property: keyof LvAddress,
  contactPerson: LvAddress | undefined,
  address: LvAddress | undefined,
) {
  if (contactPerson && isNotEmpty(contactPerson[property])) {
    return contactPerson[property];
  }
  return pathOr("", [property], address);
}

export function mergeLeftPersonAndAddress(
  contactPerson: LvAddress | undefined,
  address: LvAddress | undefined,
) {
  return {
    customerName: getLvAddressProperty("company", contactPerson, address),
    title: getLvAddressProperty("salutation", contactPerson, address),
    firstname: getLvAddressProperty("firstname", contactPerson, address),
    surname: getLvAddressProperty("surname", contactPerson, address),
    street: getLvAddressProperty("street", contactPerson, address),
    houseNumber: getLvAddressProperty("houseNumber", contactPerson, address),
    zip: getLvAddressProperty("zip", contactPerson, address),
    city: getLvAddressProperty("city", contactPerson, address),
    phone: getLvAddressProperty("phone", contactPerson, address),
    email: getLvAddressProperty("email", contactPerson, address),
  };
}

export function addUniqueIdToArray<T>(array: T[]) {
  return map((element) => assoc("id", uuidv4(), element), array);
}

async function analyzePositionText(text: string, signal?: AbortSignal) {
  if (isEmpty(text)) {
    return {
      lvQuantity: 1,
      lvAnalyzedText: "",
      parameters: [],
    };
  }
  const formData = new FormData();
  formData.append("position", text);
  const { data } = await analyzePosition(formData, signal);
  return {
    lvQuantity: parseQuantity(data.result.lvQuantity.value),
    lvAnalyzedText: data.html,
    parameters: prepareParameters(data.result.parameters),
  };
}

function mergeLeftParameters(
  leftParameters: LvBase[],
  rightParameters: LvBase[],
): LvBaseExtended[] {
  return mapIndexed((_, index) => {
    const leftParameter = leftParameters.at(index)!;
    const rightParameter = rightParameters.at(index);
    if (
      isNotEmpty(leftParameter.value) ||
      isNil(rightParameter) ||
      isEmpty(rightParameter.value)
    ) {
      return {
        ...leftParameter,
        originalValue: leftParameter.value,
        isFromReference: false,
      };
    }
    return {
      ...rightParameter,
      originalValue: rightParameter.value,
      isFromReference: true,
    };
  }, leftParameters);
}

const splitParameters = reduce<
  LvBaseExtended,
  {
    lvParameters: LvBaseExtended[];
    lvConfigurationParameters: LvBaseExtended[];
  }
>(
  (acc, lvParameter) => {
    if (includes(lvParameter.name, CONFIGURATION_CHARACTERISTICS)) {
      return produce(acc, (draft) => {
        draft.lvConfigurationParameters.push(lvParameter);
      });
    }
    return produce(acc, (draft) => {
      draft.lvParameters.push(lvParameter);
    });
  },
  { lvParameters: [], lvConfigurationParameters: [] },
);

export async function initializeLvPosition(
  position: ResolvedPosition,
  signal?: AbortSignal,
) {
  const [lvTextData, lvReferenceData] = await Promise.all([
    analyzePositionText(position.lvText, signal),
    analyzePositionText(position.lvResolvedText, signal),
  ]);
  const mergedParameters = mergeLeftParameters(
    lvTextData.parameters,
    lvReferenceData.parameters,
  );
  const { lvParameters, lvConfigurationParameters } =
    splitParameters(mergedParameters);
  return {
    lvQuantity: lvTextData.lvQuantity,
    lvAnalyzedText: lvTextData.lvAnalyzedText,
    lvParameters,
    lvConfigurationParameters,
  };
}

export async function stopCurrentPositionConfiguration(
  selectedPositionId: LvTable["selectedPositionId"],
  updateLvPosition: LvTable["updateLvPosition"],
  stopConfAndGetValues: LvConfiguration["stopConfAndGetValues"],
) {
  if (isNil(selectedPositionId)) {
    return;
  }
  const guiParameters = await stopConfAndGetValues();
  if (guiParameters) {
    updateLvPosition(selectedPositionId, {
      parameters: guiParameters.parameters,
      product: isEmpty(guiParameters.product.value)
        ? undefined
        : guiParameters.product,
    });
  }
}

export async function startNextPositionConfiguration(
  itemId: string | null,
  lvPositions: LvTable["lvPositions"],
  startConfAndSetValues: LvConfiguration["startConfAndSetValues"],
) {
  const newSelectedPosition = find(propEq(itemId, "id"), lvPositions);
  if (isNil(newSelectedPosition?.parameters)) {
    return;
  }
  await startConfAndSetValues(
    newSelectedPosition.parameters,
    newSelectedPosition.product,
  );
}

export function concatReferenceString(
  lvReference: string,
  lvResolvedReference: string,
  defaultValue: string = "",
) {
  if (isEmpty(lvReference)) {
    return defaultValue;
  }

  if (isEmpty(lvResolvedReference)) {
    return lvReference;
  }

  return `${lvReference} (${lvResolvedReference})`;
}

export function getPositionIndex(
  positionId: string | null,
  lvPositions: ResolvedPosition[],
) {
  const selectedPositionIndex = findIndex(
    propEq(positionId, "id"),
    lvPositions,
  );
  const hasNoNextPosition = gte(selectedPositionIndex, length(lvPositions) - 1);
  const hasNoPreviousPosition = lte(selectedPositionIndex, 0);
  return { selectedPositionIndex, hasNoNextPosition, hasNoPreviousPosition };
}

function areMinimalParameters(
  parameters: LvBaseExtended[] | MinimalParameter[],
): parameters is MinimalParameter[] {
  return has("selectionSource", parameters[0]);
}

export function getParametersSetByUser(
  parameters: LvBaseExtended[] | MinimalParameter[],
) {
  if (areMinimalParameters(parameters)) {
    return filter(propEq("SET_BY_USER", "selectionSource"), parameters);
  }
  return parameters;
}
