import { AutoAwesome } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Divider, MenuItem, Select, styled } from "@mui/material";
import { AxiosPromise } from "axios";
import classNames from "classnames";
import { equals, isNotNil, map, not, pathOr } from "ramda";
import { useState } from "react";

import { L10n } from "@encoway/l10n";

import { ParameterTO } from "../../../../types/@encoway/Parameter";
import { Value } from "../../../../types/@encoway/Value";
import { isOriginalValue } from "../../../../utilities/lvUtils";
import {
  dropDownValuesSeparable,
  getSelectableDropDownValues,
  getUnselectableDropDownValues,
} from "../../../configurator/customizing/configuratorDropDownUtils";

export type MinimalValue = Pick<
  Value,
  "value" | "translatedValue" | "selectable" | "selected"
>;

const StyledSelect = styled(Select)({
  fontSize: "0.75rem",
  borderRadius: 0,
  "&:hover": {
    borderColor: "none",
    "& .MuiOutlinedInput-notchedOutline": {
      borderLeft: "1px solid #dbdbdb",
      borderRight: "1px solid #dbdbdb",
    },
  },
  "&.Mui-focused": {
    borderColor: "none",
    "& .MuiOutlinedInput-notchedOutline": {
      borderLeft: "1px solid #dbdbdb",
      borderRight: "1px solid #dbdbdb",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderBottom: 0,
    borderTop: 0,
    borderLeft: "1px solid #dbdbdb",
    borderRight: "1px solid #dbdbdb",
    "&:hover": {
      borderLeft: "1px solid #dbdbdb",
      borderRight: "1px solid #dbdbdb",
    },
  },
  "&.MuiSelect-nativeInput": {
    border: 0,
  },
  "& .MuiSelect-select": {
    padding: "0.5rem",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "1.1rem",
  },
});

const StyledMenuItem = styled(MenuItem)(({ theme, unselectable }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: equals("on", unselectable) ? "#b5b5b5" : "#000000",
  fontSize: "0.75rem",
  padding: "0.5rem",
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#ffffff",
    },
  },
}));

type LvDropdownItemProps = {
  name: string;
  isNotSelectable: boolean;
  isSelected: boolean;
  onMouseDown: React.MouseEventHandler<HTMLButtonElement>;
};

const LvDropdownItem = ({
  name,
  isNotSelectable,
  isSelected,
  onMouseDown,
}: LvDropdownItemProps) => (
  <button
    className={classNames("dropdown-item is-size-7 p-2", {
      "is-not-selectable": isNotSelectable,
      "is-selected": isSelected,
    })}
    onMouseDown={onMouseDown}
  >
    <p>{name}</p>
  </button>
);

type LvDropdownItemsProps = Readonly<{
  values: MinimalValue[];
  isSelected: (value: string) => boolean;
  onMouseDown: (value: string) => void;
}>;

export function LvDropdownItems(props: LvDropdownItemsProps) {
  const { values, isSelected, onMouseDown } = props;
  return (
    <>
      {map(
        ({ value, translatedValue, selectable }) => (
          <LvDropdownItem
            key={value}
            name={translatedValue}
            isNotSelectable={not(selectable) && not(isSelected(value))}
            isSelected={isSelected(value)}
            onMouseDown={() => onMouseDown(value)}
          />
        ),
        values,
      )}
    </>
  );
}

type LvDropdownProps = {
  values: MinimalValue[];
  selectedValue: { value: string; translatedValue: string };
  originalValue?: string;
  onSelectValue: (value: string) => void;
  getParameterTO?: () => AxiosPromise<ParameterTO>;
  resetValue?: string;
};

export const LvDropdown = (props: LvDropdownProps) => {
  const {
    values,
    selectedValue,
    originalValue,
    onSelectValue,
    getParameterTO,
    resetValue,
  } = props;
  const [conflictFreeValues, setConflictFreeValues] = useState(values);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  async function onOpen() {
    if (getParameterTO) {
      try {
        const result = await getParameterTO();
        setConflictFreeValues(pathOr(values, ["data", "values"], result));
      } catch (e) {
        console.warn("Could not determine conflict free values.");
        setConflictFreeValues(values);
      }
    }
    setIsOpen(true);
  }

  return (
    <StyledSelect
      value={selectedValue.value}
      onChange={(e) => onSelectValue(e.target.value as string)}
      fullWidth
      size="small"
      IconComponent={ExpandMoreIcon}
      onOpen={onOpen}
      onClose={() => setIsOpen(false)}
      open={isOpen}
      renderValue={() => selectedValue.translatedValue}
    >
      {isNotNil(resetValue) && (
        <StyledMenuItem key={resetValue} value={resetValue}>
          {L10n.format("lv_import_reset_customer_address")}
        </StyledMenuItem>
      )}
      {map(
        (element) => (
          <StyledMenuItem key={element.value} value={element.value}>
            {element.translatedValue}
            {isOriginalValue(originalValue, element.value) && (
              <AutoAwesome sx={{ fontSize: "1rem" }} />
            )}
          </StyledMenuItem>
        ),
        getSelectableDropDownValues(conflictFreeValues),
      )}
      {dropDownValuesSeparable(conflictFreeValues) && <Divider />}
      {map(
        (element) => (
          <StyledMenuItem
            key={element.value}
            value={element.value}
            unselectable="on"
          >
            {element.translatedValue}
            {isOriginalValue(originalValue, element.value) && (
              <AutoAwesome sx={{ fontSize: "1rem" }} />
            )}
          </StyledMenuItem>
        ),
        getUnselectableDropDownValues(conflictFreeValues),
      )}
    </StyledSelect>
  );
};
