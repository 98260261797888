import {
  Box,
  CircularProgress,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
} from "@mui/material";
import { isNil, map } from "ramda";

import { L10n } from "@encoway/l10n";
import { Configurator } from "@encoway/react-components";

import { LvConfiguration } from "../../../../hooks/useLvConfiguration";
import { Value } from "../../../../types/@encoway/Value";
import { ResolvedPosition } from "../../../../types/lvTable";
import { SmallBlackLabel } from "../../lvModal/LvPositionView";
import { LvCharacteristicsRow } from "./LvCharacteristicsRow";
import { LvTableHead } from "./LvTableHead";

export const ConfiguratorTableCell = styled(TableCell)`
  padding: 0 16px;
  font-size: 0.75rem;
`;

export const Title = styled("label")`
  display: flex;
  align-items: center;
  font-weight: bold;
  gap: 8px;
  &:after {
    content: "";
    background: #b5cacd;
    flex: 1;
    height: 1px;
  }
`;

type LvProductFieldProps = Readonly<{
  selectedPosition: ResolvedPosition;
  updatePosition: (id: string, newPos: Partial<ResolvedPosition>) => void;
  updatePositionParameter: (
    positionId: string,
    parameterName: string,
    parameterValue: string,
  ) => void;
  lvConfiguration: LvConfiguration;
}>;

export function LvProductField({
  selectedPosition,
  updatePosition,
  updatePositionParameter,
  lvConfiguration,
}: LvProductFieldProps) {
  const { config, isLoading } = lvConfiguration;

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress size={24} sx={{ color: "#ef7b22" }} />
      </Box>
    );
  }

  if (isNil(config)) return;

  function onSelectValue(value: Value | undefined) {
    updatePosition(selectedPosition.id, {
      product: isNil(value)
        ? undefined
        : {
            name: "doors",
            value: value.value,
            translatedValue: value.translatedValue,
            selectionSource: value.selectionSource,
          },
    });
  }

  return (
    <Stack spacing={3}>
      <Title>{L10n.format("offer_management_lv_recognized_product")}</Title>
      <Configurator
        config={config}
        lang={L10n.currentLocale()}
        options={{
          hideLinkedTree: true,
          hideInternalTree: true,
          deleteOnUnmount: false,
          dontDeleteOnIdChange: true,
          selectedPosition,
          onSelectValue,
        }}
      />
      <Box>
        <SmallBlackLabel>
          {L10n.format("offer_management_lv_additional_characteristics")}
        </SmallBlackLabel>
        <TableContainer>
          <Table>
            <LvTableHead />
            <TableBody>
              {map(
                (parameter) => (
                  <LvCharacteristicsRow
                    key={parameter.name}
                    parameter={parameter}
                    selectedPosition={selectedPosition}
                    updatePositionParameter={updatePositionParameter}
                  />
                ),
                selectedPosition.lvConfigurationParameters ?? [],
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Stack>
  );
}
