import { not } from "ramda";
import { useState } from "react";

import { L10n } from "@encoway/l10n";

import { ProgressTable } from "../../../../../components/ProgressTable";
import { Loading } from "../../../../../components/loading";
import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../../../../components/modal/modal";
import { useLocaleNumberFormat } from "../../../../../hooks/useLocaleNumberFormat";
import { mapIndexed } from "../../../../../utilities/utilities";
import { ToggleModalActionReturn } from "../../../hooks/useCartModals";
import { determineItemValidationLabel } from "../../warnings/validateCartModal/validateCartUtils";
import { hasPricingChanged } from "./updatePricesModalUtils";
import {
  PriceModalStatus,
  ValidatedCartItem,
  useUpdatePricesModal,
} from "./useUpdatePricesModal";

import "./updatePricesModal.scss";

type UpdatePricesModalProps = {
  toggleModal: ToggleModalActionReturn;
};

type AskForUpdateModalBodyProps = {
  cancelPriceUpdate: boolean;
  onSave: () => void;
  toggleModal: ToggleModalActionReturn;
};

type UpdatePricesModalBodyProps = {
  toggleModal: ToggleModalActionReturn;
  onCancel: () => void;
};

function AskForUpdateModalBody(props: Readonly<AskForUpdateModalBodyProps>) {
  const { onSave, toggleModal, cancelPriceUpdate } = props;
  const [allowedByUser, setAllowedByUser] = useState<boolean>(true);
  const allowedByUserAndNotCancelled = allowedByUser && !cancelPriceUpdate;

  return (
    <>
      <ModalHeader
        label={L10n.format("update_price_title_ask_for_update")}
        onCancel={
          allowedByUserAndNotCancelled
            ? () => setAllowedByUser(false)
            : () => toggleModal()
        }
      />
      <ModalBodyWrapper
        label={L10n.format(
          allowedByUserAndNotCancelled
            ? "update_price_body_ask_for_update"
            : "update_price_body_ask_for_update_cancel",
        )}
      />
      <ModalFooter
        saveLabel={L10n.format(allowedByUserAndNotCancelled ? "yes" : "ok")}
        onSave={allowedByUserAndNotCancelled ? onSave : () => toggleModal()}
        {...(allowedByUserAndNotCancelled && {
          onCancel: () => setAllowedByUser(false),
          cancelLabel: L10n.format("no"),
        })}
      />
    </>
  );
}

type UpdatePriceTableProps = {
  cartItems: ValidatedCartItem[];
  status: PriceModalStatus;
};

function UpdatePriceTable({
  cartItems,
  status,
}: Readonly<UpdatePriceTableProps>) {
  const { formatPrice } = useLocaleNumberFormat();

  if (status.loading) {
    return (
      <div className="loading">
        <Loading />
      </div>
    );
  }

  return (
    <ProgressTable
      tableHeader={{
        position: L10n.format("update_price_table_pos"),
        article: L10n.format("update_price_table_article"),
        oldPrice: {
          label: L10n.format("update_price_table_price_old"),
          align: "right",
        },
        newPrice: {
          label: L10n.format("update_price_table_price_new"),
          align: "right",
        },
      }}
      tableCells={mapIndexed(
        (cartItem, index) => ({
          isLoading: cartItem.validation.running,
          isSuccess: cartItem.validation.finished && cartItem.validation.valid,
          position: index + 1,
          article: determineItemValidationLabel(cartItem),
          oldPrice: formatPrice(cartItem.bruttoEpIncTz),
          newPrice: {
            sx: {
              fontWeight: hasPricingChanged(cartItem) ? "bold" : "undefined",
            },
            value: formatPrice(cartItem.validation.newPrice),
          },
        }),
        cartItems,
      )}
    />
  );
}

const UpdatePricesModalBody = (props: Readonly<UpdatePricesModalBodyProps>) => {
  const { toggleModal, onCancel } = props;
  const { status, cartItems, save, onSave } = useUpdatePricesModal();
  const savedLabel = L10n.format(
    status.error
      ? "update_price_body_running_done_with_error"
      : "update_price_body_running_done",
  );

  function handleSave() {
    if (status.finished && not(save.saved)) {
      onSave();
      return;
    }
    if (save.saved) {
      toggleModal();
    }
  }

  return (
    <>
      <ModalHeader
        label={L10n.format("update_price_title_ask_for_update")}
        {...(not(save.saved) && { onCancel })}
      />
      {save.saved ? (
        <ModalBodyWrapper label={savedLabel} />
      ) : (
        <ModalBodyWrapper
          label={L10n.format("update_price_body_running_update")}
          className="is-flex is-flex-direction-column gap-3"
        >
          <UpdatePriceTable status={status} cartItems={cartItems} />
        </ModalBodyWrapper>
      )}
      <ModalFooter
        disabled={!status.finished || (status.finished && !status)}
        saveLabel={L10n.format("ok")}
        isLoading={save.saving}
        onSave={handleSave}
      />
    </>
  );
};

export function UpdatePricesModal(props: Readonly<UpdatePricesModalProps>) {
  const { toggleModal } = props;
  const [acceptPriceUpdate, setAcceptPriceUpdate] = useState<boolean>(false);
  const [cancelPriceUpdate, setCancelPriceUpdate] = useState<boolean>(false);

  if (acceptPriceUpdate && !cancelPriceUpdate) {
    return (
      <Modal
        className="validate-cart-warning update-price"
        dialogId="update-price"
      >
        <UpdatePricesModalBody
          toggleModal={toggleModal}
          onCancel={() => setCancelPriceUpdate(true)}
        />
      </Modal>
    );
  }

  return (
    <Modal
      className="validate-cart-warning update-price"
      dialogId="update-price"
    >
      <AskForUpdateModalBody
        toggleModal={toggleModal}
        onSave={() => setAcceptPriceUpdate(true)}
        cancelPriceUpdate={cancelPriceUpdate}
      />
    </Modal>
  );
}
