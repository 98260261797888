import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@mui/material";
import classNames from "classnames";
import { equals, gt, gte, isNil, length, not, path } from "ramda";
import { useEffect, useState } from "react";

import { L10n } from "@encoway/l10n";
import { selectedValue } from "@encoway/rest-api";

import { getConflictFreeParameterTO } from "../../../../service/configurationService";
import { LvConfiguratorComponentProps } from "../../../../types/lvTable";
import {
  dropDownValuesSeparable,
  getSelectableDropDownValues,
  getUnselectableDropDownValues,
} from "../../../configurator/customizing/configuratorDropDownUtils";
import { SmallBlackLabel } from "../../lvModal/LvPositionView";
import { LvDropdownItems } from "./LvDropdown";

import "../LvTable.scss";

const ITEM_COUNT_SCROLLABLE_CONTENT = 8;

const WarningLabel = styled(SmallBlackLabel)`
  color: hsl(26.05deg 86.5% 53.53%);
  font-weight: normal;
  font-size: small;
  margin-top: 0.25em;
`;

const DropdownIcon = styled("span")`
  background-color: hsl(26.05deg 86.5% 53.53%);
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;
  width: 40px;
`;

const DropdownInfo = styled("span")`
  background-color: hsla(26.05deg 86.5% 53.53% / 50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.8rem;

  height: 40px;
`;

export const LvProductDropdown = (props: LvConfiguratorComponentProps) => {
  const { data, config } = props;
  const [dropdownActive, setDropdownActive] = useState(false);
  const [conflictFreeValues, setConflictFreeValues] = useState(data.values);

  const selectableDropDownValues = getSelectableDropDownValues(
    conflictFreeValues ?? [],
  );
  const unSelectableDropDownValues = getUnselectableDropDownValues(
    conflictFreeValues ?? [],
  );

  const isScrollable = gte(
    length(conflictFreeValues!),
    ITEM_COUNT_SCROLLABLE_CONTENT,
  );

  async function onToggleDropdown() {
    setDropdownActive((prev) => not(prev));
  }

  useEffect(() => {
    props.options!.onSelectValue(path(["selectedValues", 0], data));
    getConflictFreeParameterTO(config.id(), config.lang, data.id).then(
      (result) => {
        if (result.data.values) {
          setConflictFreeValues(result.data.values);
        }
      },
    );
  }, [data.selectedValues]);

  return (
    <div className="control is-expanded">
      <div className={classNames("dropdown", { "is-active": dropdownActive })}>
        <div className="dropdown-trigger">
          <button
            className="button is-small p-0 pl-2"
            style={{ height: "auto" }}
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            onClick={onToggleDropdown}
            onBlur={() => setDropdownActive(false)}
          >
            <span>{selectedValue(data)}</span>
            {gt(length(selectableDropDownValues), 1) && (
              <DropdownInfo>
                {L10n.format(
                  "offer_management_lv_number_of_possible_products",
                  { count: length(selectableDropDownValues) },
                )}
              </DropdownInfo>
            )}
            <DropdownIcon>
              <FontAwesomeIcon
                icon={dropdownActive ? faAngleUp : faAngleDown}
              />
            </DropdownIcon>
          </button>
        </div>
        <div className="dropdown-menu w-100" id="dropdown-menu" role="menu">
          <div
            className={classNames("dropdown-content", {
              "is-scrollable": isScrollable,
            })}
          >
            <LvDropdownItems
              values={selectableDropDownValues}
              isSelected={(value) =>
                equals(value, path(["selectedValues", 0, "value"], data))
              }
              onMouseDown={(value) => props.onValueChanged(data, value)}
            />
            {dropDownValuesSeparable(conflictFreeValues) && (
              <hr className="dropdown-divider" />
            )}
            <LvDropdownItems
              values={unSelectableDropDownValues}
              isSelected={(value) =>
                equals(value, path(["selectedValues", 0, "value"], data))
              }
              onMouseDown={(value) => props.onValueChanged(data, value)}
            />
          </div>
        </div>
      </div>
      {isNil(selectedValue(data)) && (
        <WarningLabel>
          {L10n.format("offer_management_lv_possible_products_warning")}
        </WarningLabel>
      )}
    </div>
  );
};
