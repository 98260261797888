import { AxiosError } from "axios";
import { equals, isNil } from "ramda";

import {
  refreshConfiguration,
  validateConfigurable,
  validatePrice,
  validateSparePart,
  validateSparePartPrice,
  validateSparePartWithSave,
} from "../../../../../service/cartRestService";
import { itemRole } from "../../../../../utilities/cartUtils";
import { t } from "../../../localizationUtils";
import {
  ERROR_VALIDATION_STATUS,
  VALID_VALIDATION_STATUS,
} from "../../warnings/validateCartModal/validateCartUtils";
import { ValidatedCartItem } from "./useUpdatePricesModal";

export async function getPriceConfigurableItem(cartItem: ValidatedCartItem) {
  try {
    const loadingResult = await refreshConfiguration(cartItem.articleId);
    if (isNil(loadingResult.data) || loadingResult.status !== 200) {
      return {
        ...ERROR_VALIDATION_STATUS,
        errorMsg: t("cart_validation_invalid_not_found", cartItem),
      };
    }
    const refreshPriceBody = await validatePrice(
      loadingResult.data.configurationStatus.configurationId,
    );
    if (isNil(refreshPriceBody.data) || refreshPriceBody.status !== 200) {
      return {
        ...ERROR_VALIDATION_STATUS,
        errorMsg: t("cart_validation_invalid_not_found", cartItem),
      };
    }
    return {
      ...VALID_VALIDATION_STATUS,
      errorMsg: "",
      newPrice: refreshPriceBody.data.totalPriceSumWithTZ,
      loadingResult: loadingResult.data,
    };
  } catch (error) {
    const errorMsg =
      (error as AxiosError)?.response?.status === 404
        ? t("cart_validation_invalid_article_not_found", cartItem)
        : t("cart_validation_invalid_not_found");
    return {
      ...ERROR_VALIDATION_STATUS,
      errorMsg,
    };
  }
}

export async function getPriceSparePartItem(cartItem: ValidatedCartItem) {
  try {
    const validationResult = await validateSparePart(cartItem.articleId);
    if (isNil(validationResult) || validationResult.status !== 200) {
      return {
        ...ERROR_VALIDATION_STATUS,
        errorMsg: t("cart_validation_invalid_sparepart", cartItem),
      };
    }
    const refreshPriceBody = await validateSparePartPrice(
      cartItem.articleNumber,
    );
    if (isNil(refreshPriceBody.data) || refreshPriceBody.status !== 200) {
      return {
        ...ERROR_VALIDATION_STATUS,
        errorMsg: t("cart_validation_invalid_not_found", cartItem),
      };
    }
    return {
      ...VALID_VALIDATION_STATUS,
      errorMsg: "",
      newPrice: refreshPriceBody.data.BruttoPreis,
    };
  } catch (error) {
    const errorMsg =
      (error as AxiosError)?.response?.status === 404
        ? t("cart_validation_invalid_article_not_found", cartItem)
        : t("cart_validation_invalid_sparepart");
    return {
      ...ERROR_VALIDATION_STATUS,
      errorMsg,
    };
  }
}

export async function getPriceFromCartItem(
  cartItem: ValidatedCartItem,
): Promise<ValidatedCartItem> {
  const { isConfigurableItem, isSparePartItem } = itemRole(cartItem);
  if (isConfigurableItem) {
    return {
      ...cartItem,
      validation: await getPriceConfigurableItem(cartItem),
    };
  }
  if (isSparePartItem) {
    return { ...cartItem, validation: await getPriceSparePartItem(cartItem) };
  }
  return {
    ...cartItem,
    validation: {
      valid: false,
      running: false,
      error: true,
      errorMsg: t("cart_validation_invalid_not_found", cartItem),
    },
  };
}

export async function validateArticles(cartItem: ValidatedCartItem) {
  const { isConfigurableItem, isSparePartItem } = itemRole(cartItem);
  if (isConfigurableItem) {
    await validateConfigurable(
      cartItem.articleId,
      cartItem.validation.loadingResult,
    );
  }
  if (isSparePartItem) {
    await validateSparePartWithSave(cartItem.articleId);
  }
}

export function hasPricingChanged(cartItem: ValidatedCartItem) {
  if (isNil(cartItem.validation.newPrice)) {
    return false;
  }
  return !equals(cartItem.bruttoEpIncTz.price, cartItem.validation.newPrice);
}
